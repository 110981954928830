.inbox {
    height: 600px;
}


.inbox .rowMessage {
    width: 100%;
    height: 100%;
    display: inline-flex;
}
@media (max-width: 1045px) {
    .inbox .rowMessage {
        display: block;
    }
    .inbox .rowMessage .colMessage{
        margin-top: 20px;
    }
    .inbox .rowMessage .colMessagesList{
        margin-right: 0!important;
    }
    .inbox .rowMessage .colMessagesList .messagesList{
        width: 100%;
    }
}
@media (max-width: 576px) {
    .inbox .messageWindow .topBar .search input{
        display: none;
    }
}

.inbox .rowMessage .colMessagesList {
    height: 100%;
    margin-right: 28px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}

.inbox .rowMessage .colMessage {
   width: 100%;
    height: 100%;

}

.inbox .colMessagesList .messagesList {

    padding: 0 20px 20px 20px;
    height: 100%;
    overflow-y: scroll;
    width: 260px;
}

.inbox .messagesList .headerMessages {
    margin-bottom: 16px;
}

.inbox .messagesList .title {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    display: inline-flex;
    height: 36px;
    align-items: center;
}
.inbox .messagesList .newMessage {
    color: white;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    display: inline-flex;
    background-color: #4115E0;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    float: right;
}


.inbox .messageWindow {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    height: 100%;
    width: 100%;
}

.inbox .messageWindow .topBar {
    padding: 20px;
    display: inline-flex;
    height: 72px;
    width: 100%;
    border-bottom: 1px solid #E4E8EE;
    position: relative;
}

.inbox .messageWindow .topBar .image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 32px;
}

.inbox .messageWindow .topBar .image img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.inbox .messageWindow .topBar .name {
    color: rgba(34, 35, 47, 1);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 4px;
}
.inbox .messageWindow .topBar .lastSeen {
    color: rgba(154, 159, 167, 1);
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    width: 200px;
}

.inbox .messageWindow .topBar .search input {
    width: 200px;
    padding: 6px;
}
.inbox .messageWindow .topBar .search .usersList {
    margin-top: 10px;
    width: 200px;
}

.active .bubble {
    background-color: rgba(65, 21, 224, 0.2);
}

.inbox .messageWindow .topBar .itemHeader {
    width: fit-content;
    display: inline;
}

.inbox .messageWindow .topBar input {
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    width: 320px;
}
.inbox .messageWindow .topBar .usersList{
    width: 320px;
    position: absolute;
    top: 51px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #E4E8EE;
}

.inbox .messageWindow .messages {
    height: calc(100% - 72px);
    padding: 20px;
}

.inbox .messageWindow .messages .messagesList{
    height: calc(100% - 64px);
    overflow-y: scroll;
    
}

.inbox .messageWindow .messages .messagesList::-webkit-scrollbar, .inbox .colMessagesList .messagesList::-webkit-scrollbar {
    width: 3px;
}
.inbox .messageWindow .messages .messagesList::-webkit-scrollbar-thumb,.inbox .colMessagesList .messagesList::-webkit-scrollbar-thumb {
    background: #c8cacd;
    border-radius: 4px;
    float: bottom;
    position: fixed;
    bottom: 0;
}
.inbox .messageWindow .messages .messagesList::-webkit-scrollbar-track, .inbox .colMessagesList .messagesList::-webkit-scrollbar-track{
    background: #F3F3F3;
    border-radius: 4px;
}

.inbox .colMessage .messagesList .message-list-container {
    height: 100%;
    padding-right: 20px;
}


.inbox .messageWindow .messages .messagesList img {
    width: 48px;
    height: 48px;
}

.inbox .messageWindow .messages .addMessage {
    padding: 12px 20px;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}
.inbox .messageWindow .messages .addMessage input {
    background: #FFFFFF;
    border: 0.5px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 16px;
    width: calc(100% - 113px);
    outline: none;
}

.inbox .messageWindow .messages .addMessage img {
    margin-right: 10px;
}


.inbox .dropdown {
    width: 100%;
    position: relative;
    left: 0;
}

.inbox .dropdown .btn-info {
    background-color: transparent;
    border: 0!important;
    box-shadow: none!important;
    float: right;
}

.inbox .dropdown-toggle::after{
    display: none;
    border: 0;
}

.inbox .dropdown-menu {
    padding: 12px 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 180px;
}