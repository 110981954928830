name.puJobHistory .description {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: rgba(34, 35, 47, 1);
    margin-bottom: 32px;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

table.companies thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 12px 16px;
    width: 16%;
    text-align: left;
}


table.companies tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 12px 16px;
    text-align: center;
    width: 16%;
    height: 40px;
}

table.companies tbody td div{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

table.companies tbody td .units{
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

table.companies .name {
    width: 35%;
    text-align: left;
}
table.companies .name img {
    margin-right: 10px;
}

table.companies .date{
    font-size: 10px;
    line-height: 14px;
}

table.companies .budget{
    color: rgba(0, 0, 0, 1);
}

table.companies .commission{
    color: rgba(65, 21, 224, 1);
}

table.companies .date .dateEnd {
    background: #FFE0E0;
    border-radius: 10px;
    width :70px;
    margin-left: 8px;
    color: black;
    height: 14px;
    text-align: center;
    justify-content: center;
    padding: 2px;
}

