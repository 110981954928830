body{
    background-color: #E5E5E5;
    font-family: Montserrat;
}

.sideMenu{
    background: #FFFFFF;
    box-shadow: 0px 5px 11px rgba(31, 72, 72, 0.13);
    border-radius: 6px;
    min-height: 100vh;
}

.sideMenu .title{
    margin-top: 32px;
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: rgba(115, 103, 240, 1);
    justify-content: center;
    display: flex;
}

.sideMenu .currentPath {
    max-width: 240px;
    height: 42px;
    background: #F8F8F8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.sideMenu .currentPath img:first-child{
    margin-left: 10px;
}

.sideMenu .currentPath .badge{
    background-color: #7367F0;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    display: inline-block;
    margin-left: 10px;
    margin-right: 2px;
}

.sideMenu .opBadge.badge{
    color: white;
    background-color: #aba4f6!important;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    display: inline-block;
    position: absolute;
    right: 0;
}

.sideMenu .opBadge.badge text {
    position: absolute;
    left: 0;
}

.sideMenu a{
    text-decoration: none;
    padding: 0;
}

.sideMenu div{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(34, 35, 47, 1);
    padding: 0 10px;
    position: relative;
}
.sideMenu a{
    padding: 0 10px;
}

.sideMenu div img{
    margin-right: 12px;
    width: 17px;
    height: 17px;
}

.sideMenu .opportunityTitle{
    margin: 48px 0 0 0;
}

.pageContent {
    padding: 0 28px 28px 18px;
}



.header{
    margin-top: 24px;
    margin-bottom: 32px;
    background: #FFFFFF;
    border-radius: 6px;
    height: 62px;
    padding: 0 13px;
    display: flex;
    flex-direction: row;
}

.header .notifications{
    display: flex;
    height: 100%;
    align-items: center;
}

.header .icon{
    width: 18px;
    height: 18px;
    margin: 0px 7px;
}

.header .itemHeader{
    position: relative;
}

.header .notifications .badge{
    background-color: #7367F0;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    position: absolute;
    right: -2px;
    top: -8px;

}

.header .search {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    align-content: center;
}
@media (max-width: 730px) {
    .header .search {
        display: none;
    }
}

.header .search .cont {
    margin-left: auto;
    position: relative;
}

.header .search .cont img {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 15px;
}

.header .search .cont input {
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 6px;
    width: 283px;
    height: 36px;
    padding: 10px 18px;
}

.header .search .cont input::placeholder{
    color: #9A9FA7;
}

.header .account {
    align-items: center;
    display: flex;
}
@media (max-width: 465px) {
    .header .account {
        display: none;
    }
}
.header .account .language .flag {
    height: 24px;
}

.header .account .btn {
    background-color: transparent;
    color: #505161;
    font-weight: 500;
    font-size: 16px;
    border: 0;
}

.header .btn .title {
    margin: 0 10px 0 7px;
    display: inline-block;
}
.header .dropdown .btn-info, .report .dropdown .btn-info {
    background-color: transparent;
    border: 0!important;
    box-shadow: none!important;
}

.header .dropdown-toggle::after, .report .dropdown-toggle::after {
    display: none;
    border: 0;
}

.header .dropdown-menu, .report .dropdown-menu {
    padding: 12px 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 316px;
}

.header .usersList{
    width: 283px;
    position: absolute;
    top: 51px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #E4E8EE;
    right: 0;
    z-index: 10000000000;
}

.header .dropdown-menu.language, .report {
    width: 110px;
    min-width: 90px;
    transform: translate(0px, 37px)!important;
}

.header .notifications .dropdown .title{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: black;
    display: inline-block;
}

.header .notifications .dropdown .markRead{
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 207, 232, 1);
    display: inline-block;
    float: right;
    position: relative;
    top: 5px;
    
}

.sideMenu .title img {
    height: 50px;
    width: unset;
}

.sideMenu .containerLink {
    display: inline-flex;
    width: calc(100% - 10px);
    padding-left: 0;
}

