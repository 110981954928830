.messageItem {
  display: flex;
  flex-direction: column;
}

.messageItem .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.messageItem .bubble-container {
  font-size: 14px;
  display: flex;
}

.messageItem.mine .bubble-container {
  justify-content: flex-end;
}

.messageItem.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.messageItem.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}
div.active .messageItem.end .bubble-container .bubble {
  background-color: rgba(65, 21, 224, 0.3);
}

.messageItem.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.messageItem.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.messageItem .bubble-container .bubble {
  margin: 1px 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: rgba(65, 21, 224, 0.1);
  padding: 12px 16px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.messageItem.mine .bubble-container .bubble {
  background: rgba(65, 21, 224, 0.1);
  
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.image_message, .image_message img{
  width: 48px;
  border-radius: 50%;
}