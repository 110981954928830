
.opportunity {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 2px 5px 11px rgba(31, 72, 72, 0.13);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.opportunity .logoCont{
    width: 98px;
    height: 98px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.12);
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 24px;
    font-weight: 700;
    margin: auto;
}

.opportunity .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: black;
    margin-bottom: 12px;
    
}
.opportunity .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #505161;
    margin-bottom: 20px;
    
}

.opportunity .attrIcon {
    width: 14px;
    margin-right: 10px;
    position: relative;
    top: -2px;
}

.opportunity .attrTitle{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #505161;
}
.opportunity .attrVal{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: black;
}

.opportunity .editCol{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.opportunity .editCol .btn {
    max-width: 116px;
}

.opportunity .editOportunity{
    position: absolute;
    top: 0;
    right: 22px;
    height: 18px;
    cursor: pointer;
}