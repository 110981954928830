/* === GLOBAL STYLES === */
* {
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
}

.bg-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* === HEADER STYLES === */
header {
    text-align: center;
    padding: 20px 0;
    background-color: #fff;
    border-radius: 5px;
}

header .logos img {
    margin: 0 10px;
    width: 150px;
}

header p {
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    color: #5d446e;
}

/* === NAVIGATION STYLES === */
.nav {
    height: 50px;
    width: 100%;
    position: relative;
}

.nav > .nav-header {
    display: inline;
}

.nav > .nav-header > .nav-title {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 10px;
}

.nav-title img {
    max-width: 45%;
    height: auto;
    vertical-align: middle;
}

.nav > .nav-btn {
    display: none;
}

.nav > .nav-links {
    right: 0;
    position: absolute;
    top: 0;
    font-weight: bold;
}

.nav > .nav-links > a {
    display: inline-block;
    padding: 13px 10px;
    text-decoration: none;
    color: #5d446e;
}

.nav > .nav-links > a:hover {
    background-color: rgba(0, 150, 136, 0.3);
}

.nav > #nav-check {
    display: none;
}

.language-dropdown {
    position: relative;
    display: inline-block;
}

.language-dropdown button {
    padding: 13px 10px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    color: #5d446e;
    background-color: transparent;
}

.language-dropdown:hover button {
    background-color: rgba(0, 150, 136, 0.3);
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    background-color: rgba(0, 150, 136, 0.3);
    z-index: 1;
}

.dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 18px;
}

.language-dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a:hover {
    background-color: rgba(0, 150, 136, 0.3);
}

/* === TWO COLUMN STYLES === */
.two-column {
    display: flex;
    gap: 20px;
}

.text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    flex: 0.5;
    color: #5d446e;
}

.text-content h2 {
    margin-left: 0;
    margin-top: 0;
}

.text-content p {
    font-size: 18px;
    line-height: 1.8;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.image-content {
    flex: 1;
    max-width: 50%;
}

.image-content img {
    max-width: 90%;
    height: auto;
    display: block;
    margin: 0 auto;
    align-items: center;
}

/* === TWO COLUMN 2/1 STYLES === */
.two-column-section1 {
    display: flex;
    gap: 20px;
    margin-bottom: 50px;
}

.image-column1 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-column1 img {
    max-width: 90%;
    height: auto;
}

.text-column {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: right;
}

.text-column p {
    font-size: 18px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #5d446e;
    line-height: 1.8;
    width: 100%;
}

/* === SECOND TWO-COLUMN SECTION === */
.two-column-section2 {
    display: flex;
    gap: 20 px;
    margin-bottom: 50px;
}

.image-column2 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-column2 img {
    max-width: 70%;
    height: auto;
}

.text-column2 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.text-column2 p {
    width: auto;
    font-size: 18px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #5d446e;
    line-height: 1.8;
}

/* === CARD SECTION STYLES === */
.card-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;
    gap: 30px;
}

.card {
    flex: 1;
    max-width: 45%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(74, 153, 210, 0.25);
}

.card img {
    max-width: 30%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.card h1 {
    font-size: 24px;
    font-weight: bold;
    color: #5d446e;
    margin-bottom: 10px;
    text-align: center;
}

.card p {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 16px;
    line-height: 1.5;
    color: #5d446e;
    padding: 0 10px;
    text-align: center;
}

/* === FOOTER STYLES === */
footer {
    text-align: center;
    padding: 20px 0;
    color: #5d446e;
    border-radius: 5px;
}

footer .newsletter {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 0;
}

footer .newsletter input, footer .newsletter button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
}

footer .newsletter button {
    background-color: #e67e22;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

footer .newsletter button:hover {
    background-color: #d35400;
}

footer .footer-logos img {
    margin: 0 10px;
    width: 180px;
}

footer .social-icons img {
    width: 40px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {

    /* Navbar Mobile Styles */
    .nav > .nav-btn {
        display: inline-block;
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .nav > .nav-btn > label {
        display: inline-block;
        width: 50px;
        height: 50px;
        padding: 13px;
    }

    .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
        background-color: rgba(0, 150, 136, 0.3);
    }

    .nav > .nav-btn > label > span {
        display: block;
        width: 25px;
        height: 10px;
        border-top: 2px solid #5d446e;
    }

    .nav > .nav-links {
        position: absolute;
        display: block;
        width: 100%;
        background-color: #e2eff8;
        height: 0px;
        transition: all 0.3s ease-in;
        overflow-y: hidden;
        top: 50px;
        left: 0px;
    }
      
    .nav > .nav-links > a {
        display: block;
        width: 100%;
      }

    .nav > #nav-check:not(:checked) ~ .nav-links {
        height: 0px;
    }

    .nav > #nav-check:checked ~ .nav-links {
        height: calc(100vh - 50px);
        overflow-y: auto;
    }

    .two-column, .three-column, .card-section {
        flex-direction: column;
        align-items: center;
    }

    .image-content {
        max-width: 100%; /* Allow image container to take up full width on smaller screens */
    }

    .two-column-section1 {
        flex-direction: column; /* Stack columns vertically with image below text */
    }

     .two-column-section2 {
        flex-direction: column-reverse; /* Stack columns vertically with image below text */
    }

    .card {
        max-width: 100%; /* Cards will take the full width on mobile */
        padding: 15px; /* Slightly reduce padding to give more space to the content */
    }

    .card p {
        font-size: 18px; /* Slightly increasing the font size for better readability on mobile */
        padding: 0 5px; /* Adjusting the horizontal padding for the paragraph */
    }

}


