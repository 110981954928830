.viewOps .maintitle{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.viewOps .maintitle div {
    display: inline-block;
}

.viewOps .maintitle a img{
    margin-right: 10px;
}

.viewOps .maintitle div:last-child {
    float: right;
}

.viewOps .filters{
    margin-bottom: 8px;
    display: inline-flex;
}

.viewOps .filters .item {
    margin-right: unset;
    width: 196px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    user-select: none;
}

.viewOps .filters a{
    text-decoration: none;
    color: rgba(80, 81, 97, 1);
}

.viewOps .filters .item img {
    width: 12px;
    margin-right: 12px;
}

.viewOps .filters .item.checkbox{
    display: inline-flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.viewOps .filters .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.viewOps .item.select {
    height: fit-content;
}

.viewOps .item.select div {
    height: fit-content;
    position: unset;
    margin-top: 0;
}