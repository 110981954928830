.packages {
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;
}

.packages .mainTitle {
    margin-top: 57px;
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 20px;
}

.packages .firstContainer {
    background: #FFFFFF;
    box-shadow: 0px 5px 12px rgba(183, 183, 183, 0.25);
    border-radius: 6px;
    padding: 26px;
    margin-top: 60px;
}

.packages .firstContainer .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 16px;
}

.packages .firstContainer .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgba(20, 20, 20, 1);
}

.packages .mainDesc {
    width: 60%;
    margin: auto;
}

.packages .secondContainer {
    background: #FFFFFF;
    box-shadow: 0px 5px 12px rgba(183, 183, 183, 0.25);
    border-radius: 6px;
    margin-top: 60px;
    padding: 24px;
    height: calc(100% - 20px);
    position: relative;
}

.packages .secondContainer.active {

    box-shadow: 0px 5px 12px rgba(65, 21, 224, 0.6);
}

.packages .secondContainer .title {
    padding: 26px;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}

.packages .secondContainer .packageList {
    text-align: left;
}
.packages .secondContainer .packageList div {
    margin-bottom: 16px;
}

.packages .secondContainer button {
    position: absolute;
    bottom: 24px;
    left: 24px;
    width: calc(100% - 48px);
}