.fileCard {
    min-width: 232px;
    min-height: 260px;
    width: calc(25% - 53px);
    display: inline-block;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-right: 53px;
    margin-bottom: 21px;
    overflow: hidden;
}

.fileCard .imageCont {
    height: 149px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 8px;
    position: relative;
}

.fileCard .imageCont.yellow {
    background-color: rgba(255, 177, 10, 0.1);
}
.fileCard .imageCont.purple {
    background-color: rgba(65, 21, 224, 0.1);
}
.fileCard .imageCont.green {
    background-color: rgba(5, 179, 127, 0.1);
}
.fileCard .imageCont.blue {
    background-color: rgba(10, 93, 255, 0.1);
}

.fileCard .imageCont .actions {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.customDropdown{
    background-color: transparent;
}


.customDropdown .btn::after{
    display: none;
}

.fileCard .title{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 16px;
}

.fileCard .size {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(154, 159, 167, 1);
    margin: 8px 0 8px 16px;
    
}

.fileCard .lastAccessed {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(80, 81, 97, 1);
    margin-left: 16px;
    margin-bottom: 16px;
    
}