.financeInvoices .title {
    color: black;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
}

.financeInvoices .invoicesList{
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
}
.financeInvoices .table-container{
    overflow-x: auto;
}

.financeInvoices table.invoices img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
}

.addPayment input {
    outline: none;
    margin: 16px;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 4px;
}

.addPayment button {
    margin-right: 16px;
}