.finance .printButton{
    float: right;
}

.finance .printButton img{
    margin-right: 10px;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

table.sellers thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 12px 16px;
    width: 16%;
    text-align: center;
}


table.sellers tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 12px 16px;
    text-align: center;
    width: 16%;
}

table.sellers tbody td img{
    margin-right: 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

table.sellers tbody td input {
    outline: none;
    height: 39px;
    border: 0;
}

table.sellers td.name,table.sellers th.name {
    text-align: left;
    width: 20%;
    padding: 8px 16px;
}
table.sellers td.dat {
    color: rgba(65, 21, 224, 1);
}
table.sellers td.obi {
    color: rgba(255, 177, 10, 1);
}


table.sellers thead th.empty{
    border: 0;
    background: #FFFFFF;
}
table.sellers thead th.green{
    color: rgba(5, 179, 127, 1);
    border: 1px solid #E4E8EE;
    background: rgba(5, 179, 127, 0.1);
}

table.sellers thead th.green div {
    flex-grow: 1;
    align-items: center;
    width: 100%;
    align-self: center;
}

table.sellers thead th.green .info{
    width: 50%;
    height: 100%;
    display: inline-block;
}


