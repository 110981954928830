.companyHeader {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
}

.companyHeader .logoCont{
    width: 132px;
    height: 132px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(115, 103, 240, 0.12);
    align-items: center;
    justify-content: center;
    display: flex;
    color: #7367F0;
    font-size: 24px;
    font-weight: 700;
    margin: auto;
}
.companyHeader .logoCont img {
    width: 132px;
    height: 132px;
}

.companyHeader .companyTitle{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: black;
    display: flex;
    justify-content: center;
    margin: 16px 0 8px 0;
}

.companyHeader .companyDomain{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #505161;
    text-align: center;
}

.companyHeader .companyDesc{
    margin: 12px 40px 0 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}
.companyHeader button{
    margin-top: 26px;
}
.companyHeader .col-md-3{
    margin-top: 26px;
}

.companyHeader .separator {
    background: linear-gradient(0deg, rgba(228, 232, 238, 0) 0%, #E4E8EE 52.08%, rgba(228, 232, 238, 0) 100%);
    position: absolute;
    right: 0;
    top: 0;
    height: 216px;
    width: 1px;
}

.contactInfo {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 0 0 40px;
}

.contactInfo .element{
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.contactInfo .element .icon{
    width: 15px;
    margin-right: 18px;
    
}