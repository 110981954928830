.leads .mainTitle {
    padding: 0;
    margin: 0;
}

.leads .mainTitle div{
    margin-bottom: 32px;
    display: inline-flex;
}

.leads .mainTitle  div:last-child{
    float: right!important;
}

.leads .mainTitle a img{
    margin-right: 10px;
}

.leads .list {
    padding: 0;
    margin: 0;
}

