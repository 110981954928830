.companySettings p{
    margin-bottom: 24px;
}

.companySettings .item {
    margin-right: 0;
}

.companySettings h3{
    margin-top: 8px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: black;
}

.companySettings .subtitle{
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: black;
}

.companySettings .companyDescription{
    background: #FFFFFF;
    border: 0.5px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(185, 185, 195, 0.2);
    border-radius: 4px;
    height: 148px;
    display: block;
    width: 100%;
    margin-bottom: 24px;
}

.companySettings .aboutCollab{
    display: inline-block;
    margin-bottom: 24px;
}

.companySettings .aboutCollab div{
    display: inline-block;
    width: fit-content;
}



