.discussionItem {
    display: inline-flex;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
}

.discussionItem.active {
    background-color: rgba(65, 21, 224, 0.1);
}

.discussionItem.unread {
    background-color: rgba(65, 21, 224, 0.2);
}

.discussionItem .image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 10px;
}

.discussionItem .image img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.discussionItem .name {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
}

.discussionItem .lastMessage {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
}