.dateSelector {
    background: #FFFFFF;
    border: 0.5px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 265px;
    height: 40px;
    display: inline-flex;
    margin-right: 20px;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
}

.dateSelector .value{
    flex-grow: 1;
    text-align: center;
    padding: 0 10px;
    user-select: none;
}

.dateSelector .action {
    padding: 0 25px ;
    cursor: pointer;
    user-select: none;
}

