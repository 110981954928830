.fileContainer img{
    position: absolute;
    top: 0;
    right: 0;
}

.companyFiles .addFile {
    display: inline-block;
    position: absolute;
    right: 20px;
}

.companyFiles .addFile img{
    margin-right: 10px;
}

.companyFiles .uploadFile {
    position: absolute;
    display: inline-flex;
    right: 164px;
    
    height: 40px;
}

.companyFiles .uploadFile input button {
    background: #FFFFFF;
    color: #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15);
    border-radius: 4px;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
}