.report .item {
    width: 140px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    user-select: none;
    padding: 16px;
}

.report .item img{
    width: 12px;
    margin-right: 12px;
}

.report .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.report .dropdown {
    display: inline-flex;
}

.report .dropdown-toggle {
    padding: 0;
}

.report table.tasks tbody td.actions{
    align-items: start;
}

.report table.tasks tbody td.actions img.mess{
    margin-bottom: 8px;
    float: start;
    align-self: start;
}

.report .dropdown-menu {
    width: 150px;
    transform: translate(-112px, 24px);
    cursor: pointer;
}