.loEdit h1{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.loEdit h2{
    margin-bottom: 26px;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
}

.loEdit h3{
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}
.loEdit h5{
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

.loEdit .info {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 32px;
}

.loEdit .info .option{
    width: fit-content;
    display: inline-block;
}

.loEdit .info .option input{
    display: inline-block;
    margin-right: 10px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 2px;

}

.loEdit .info .option input:checked{
    background: red;
}


.loEdit .info .option p {
    display: inline-block;
    margin-right: 35px;

}

.loEdit .desciption{
    background: #FFFFFF;
    border: 0.5px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(185, 185, 195, 0.2);
    border-radius: 4px;
    height: 148px;
    display: block;
    width: 100%;
}


.loEdit .files {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 32px;
}

.loEdit .files .addFile {
    display: inline-block;
    position: absolute;
    right: 58px;
}

.loEdit .files .addFile img{
    margin-right: 10px;
}

.loEdit .files .saveLead ,.loEdit .infoTask .createTask {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    border-top: 1px solid #E4E8EE;
    padding-top: 20px;
    padding-left: 20px;
}
.loEdit .files .saveLead .btn,.loEdit .infoTask .createTask  .btn{
    width: 230px;
    height: 40px;
}

.loEdit .infoTask {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 32px;
}

.loEdit .infoTask .btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-right: 20px;
}

.loEdit .infoTask .btn img {
    margin-right: 10px;
}

.loEdit .form-container {
    background: transparent!important;
}

.saveFiles Ă


/*
Confirm alert
*/
.react-confirm-alert .react-confirm-alert-body{
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: black;
    width: 611px;
}

.react-confirm-alert h1{
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: black;
}
.react-confirm-alert .react-confirm-alert-button-group {
    position: relative;
    justify-content: flex-end;
}

.react-confirm-alert .react-confirm-alert-button-group button{
    background: #FFFFFF;
    color: #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15);
    border-radius: 4px;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
}

.react-confirm-alert .react-confirm-alert-button-group button:last-child{
    background: #4115E0;
    color: white;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.25);
    border-radius: 4px;
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    border-color: #4115E0;
}





