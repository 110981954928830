@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.terms {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.terms .background {
    width: 100%;
    height: 70%;
    display: flex;
    position: absolute;
    top: 0;
    justify-content: center;
}

.terms .img1,.terms .img2,.terms .img3,.terms .img4 {
    display: flex;
    position: absolute;
}

.terms .img1 {
    width: 721px;
    display: flex;
    align-self: center;
}

.terms .img2 {
    width: 135px;
    bottom: 40px;
    right: 0px;
}

.terms .img3 {
    width: 819px;
    bottom: 40px;
    left: calc(50% - 410px);
}

.terms .img4 {
    left: 0;
    bottom: 40px;
}

.terms .login {
    z-index: 10000;

}

.terms .fcontainer {
   align-self: center;
    overflow: hidden;
    width: 60vw;
    height: 100%;
    z-index: 100;
    left: 536px;
    top: 267px;
    border-radius: 6px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    display: block;
    padding: 25px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

.terms .fcontainer::-webkit-scrollbar {
    display: none;
}


.terms .form-container{
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
}

.terms .form-control:focus {
    background: transparent!important;
}

.terms input:-webkit-autofill {
    background-color: transparent!important;
}

.terms .login_title{
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
}
.terms .login_subtitle {
    font-family: Montserrat;
    color: rgba(80, 81, 97, 1);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

