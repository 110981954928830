.notification .separator {
    border: 0.5px solid #E4E8EE;
    margin: 12px 0;
}

.notification .itemNot {

    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;
    padding-right: 0;
    width: 44px;
    height: 32px;
}

.notification .itemImg {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.notification .itemImg img {

    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
}

.notification .itemImg.purple {
    background-color: rgba(65, 21, 224, 0.1);;
}
.notification .itemImg.yellow {
    background-color: rgba(255, 177, 10, 0.1);;
}
.notification .itemImg.green {
    background-color: rgba(5, 179, 127, 0.1);;
}
.notification .itemImg.blue {
    background-color: rgba(0, 207, 232, 0.1);;
}

.notification .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: black;
}
.notification .desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(154, 159, 167, 1);
}
.notification .date {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(154, 159, 167, 1);
    text-align: end;
}