.myOpportunity {
    padding: 0;
    margin: 0;
}

.myOpportunity .title div{
    margin-bottom: 32px;
    display: inline-flex;
}

.myOpportunity .title {
    padding: 0;
}

.myOpportunity .title a img{
    margin-right: 10px;
}

.myOpportunity .title  div:last-child{
    float: right;
}