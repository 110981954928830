.dashboard .item {
    background: #FFFFFF;
    box-shadow: 0px 5px 11px rgba(51, 58, 58, 0.13);
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    padding: 20px;
    height: calc(100% - 28px);
    margin: 0 0 28px 0;
}

.dashboard .item h2 {
    margin-bottom: 32px;
}

.dashboard .item a {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(34, 35, 47, 1);
    cursor: pointer;
}

.dashboard .item.congrat h2 {
    margin-bottom: 6px;
}

.dashboard .item.congrat .desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    margin-bottom: 8px;
}
.dashboard .item.congrat .views {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: rgba(65, 21, 224, 1);
    margin-bottom: 8px;
    margin-top: 48px;
}
.dashboard .item.congrat button {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    background: rgba(65, 21, 224, 0.8);
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.12);
    border-radius: 6px;
    height: 32px;
    padding: 8px 15px;
}

.dashboard .item.congrat .congratImage img{
    float: right;
}

.dashboard .item .statisticIdem {
    margin: auto;
}

.dashboard .item .statisticIdem .statisticImage { 
    width: 48px;
    height: 48px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.dashboard .item .statisticIdem .statisticImage.green {
    background: rgba(5, 179, 127, 0.2);
}
.dashboard .item .statisticIdem .statisticImage.yellow {
    background: rgba(255, 177, 10, 0.3);
}
.dashboard .item .statisticIdem .statisticImage.blue {
    background: rgba(0, 207, 232, 0.3);
}

.dashboard .item .statisticIdem .statisticInfo img {
    width: 20px;
    margin-left: 5px;
    margin-top: -4px;
}

.dashboard .item .statisticIdem .statisticInfo h3{

    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 1);
}

.dashboard .item.chart {
    display: flex;
    height: 295px;
}

.dashboard .item .chart1 {
    padding: 54px 0 0 0 ;
    width: calc(84% - 20px);
    display: flex;
}

.dashboard .item .chart2 {
    padding: 54px 0 0 0 ;
    width: 70%;
    display: flex;
}

.dashboard .item .chart2 .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(94, 88, 115, 1);
    position: absolute;
    top: 20px;
    left: 20px;
}

.dashboard .item .chart1Statistic {
    width: 16%;
    display: inline-flex;
    align-items: center;
    align-self: center;
    flex-direction: column;
    margin-left: 20px;
}

.dashboard .item .chart1Statistic .crtItem {
    border: 5px solid rgba(5, 179, 127, 0.12);
    box-sizing: border-box;
    width: 100%;
    aspect-ratio : 1;
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: 16px 25px;
}

.dashboard .item .chart1Statistic .crtItem:first-child {
    margin-bottom: 20px;
}

.dashboard .item .chart1Statistic .title {
    align-self: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: rgba(5, 179, 127, 1);
    margin-bottom: 3px;
}

.dashboard .item .chart1Statistic .crtItem.yellow{
    border: 5px solid rgba(255, 177, 10, 0.12);
}

.dashboard .item .chart1Statistic .crtItem.yellow .title {
    color: rgba(255, 177, 10, 1);
}

.dashboard .item .chart1Statistic .description {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
}
.dashboard .item .chart1Statistic img {
    width: 12px;
    margin-top: -4px;
}


.dashboard .item .chart2Statistic {
    width: 225px;
    margin-left: 16px;
    position: relative;
}

.dashboard .item .chart2Statistic .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(34, 35, 47, 1);
    margin-bottom: 12px;
    margin-top: 54px;
}

.dashboard .item .chart2Statistic .itemCS {
    margin-bottom: 12px;
}

.dashboard .item .chart2Statistic .itemCS .title {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: rgba(80, 81, 97, 1);
    margin: 0;
}

.dashboard .item .chart2Statistic .itemCS .desc{
    font-weight: 400;
    font-size: 8px;
    line-height: 18px;
    color: rgba(154, 159, 167, 1);
    display: inline-flex;
   
    
}

.dashboard .item .chart2Statistic .itemCS .value {
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    color: rgba(34, 35, 47, 1);
    float: right;
    display: inline-flex;
    margin-top: 5px;
}

.dashboard .item .chart2Statistic .separator {
    background: #E5E5F0;
    border-radius: 0px;
    transform: rotate(90deg);
    width: 1px;
    height: 217px;
    position: absolute;
    left: 100px;
    bottom: -24px;
}


.dashboard .item .chart2Statistic .objective .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(34, 35, 47, 1);
    margin: 28px 0 10px 0;
}

.dashboard .item .chart2Statistic .objective .value {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(255, 177, 10, 1);
    margin-bottom: 6px;
}

.dashboard .item .chart2Statistic .objective .progress{
    height: 5px;
    background-color: rgba(243, 243, 243, 0.6);
    border-radius: 10px;

}

.dashboard .item .chart2Statistic .objective .progress .progress-bar{
    background-color: #FFB10A!important;
    border-radius: 10px;

}


.dashboard .item .itemActivity {
    margin-bottom: 26px;
}

.dashboard .item .itemActivity .itemImg {
    border-radius: 26px;
    height: 48px;
    width: 48px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.dashboard .item .itemActivity .itemImg img {
    width: 18px;
}

.dashboard .item .itemActivity.purple .itemImg {
    background: rgba(65, 21, 224, 0.12);
}
.dashboard .item .itemActivity.green .itemImg {
    background: rgba(5, 179, 127, 0.12);;
}
.dashboard .item .itemActivity.yellow .itemImg {
    background: rgba(255, 177, 10, 0.12);;
}
.dashboard .item .itemActivity.blue .itemImg {
    background: rgba(0, 207, 232, 0.12);
}

.dashboard .item .itemActivity .title{
    display: inline-flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    margin-bottom: 12px;
}
.dashboard .item .itemActivity .activityProgress{
    display: inline-flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    float: right;
}

.dashboard .item .itemActivity .progress{
    height: 5px;
    background-color: rgba(243, 243, 243, 0.6);
    border-radius: 10px;

}

.dashboard .item .itemActivity.purple .progress .progress-bar{
    background-color: #4115E0!important;
    border-radius: 10px;

}
.dashboard .item .itemActivity.green .progress .progress-bar{
    background-color: #05B37F!important;
    border-radius: 10px;

}
.dashboard .item .itemActivity.yellow .progress .progress-bar{
    background-color: #FFB10A!important;
    border-radius: 10px;

}
.dashboard .item .itemActivity.blue .progress .progress-bar{
    background-color: #00CFE8!important;
    border-radius: 10px;

}

.itemOpportunity {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #F8F8F8;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}

.itemOpportunity .logoCont{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
}

.itemOpportunity.purple .logoCont{
    background: #4115E0;
}
.itemOpportunity.yellow .logoCont{
    background: #FFB10A;
}
.itemOpportunity.green .logoCont{
    background: #05B37F;
}
.itemOpportunity.blue .logoCont{
    background: #00CFE8;
}


.itemOpportunity .title{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(80, 81, 97, 1);
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

.itemOpportunity .dateStart{
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: rgba(155, 155, 155, 1);
    text-align: center;
    width: fit-content;
    float: left;
}
.itemOpportunity .dateEnd{
    width: fit-content;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: rgba(155, 155, 155, 1);
    text-align: center;
    background: rgba(222, 2, 10, 0.2);
    border-radius: 2px;
    padding: 2px 4px;
}


.itemOpportunity .separator {
    background: linear-gradient(0deg, rgba(228, 232, 238, 0) 0%, #E4E8EE 52.08%, rgba(228, 232, 238, 0) 100%);
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    width: 1px;
}

.itemOpportunity .detailsOp {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.itemOpportunity .value {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: rgba(65, 21, 224, 1);
    margin-bottom: 12px;
}

.itemOpportunity .details {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
}

.itemOpportunity .details img{
    width: 12px;
    margin-left: 5px;
}

.tableALO .tab {
    background: #F3F3F3;
    border-radius: 4px 4px 0px 0px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: black;
    padding: 10px 18px;
}

.tableALO .tabsALO {
    height: calc(100% - 48px);
}

.tableALO .col.active .tab, .tableALO .col-md-4.active .tab{
    background: white;
}

.tableALO  a{
    text-decoration: none;
}

.tableALO .item table {
    width: 100%;
}

.tableALO .item .table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

.tableALO .item table thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 8px;
    text-align: center;
    height: 40px;
}


.tableALO .item table tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 8px;
    text-align: center;
    height: 40px;
}

.tableALO .item table tbody td img{
    margin-right: 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.tableALO .item table td.name,.tableALO .item table th.name {
    text-align: left;
    width: 20%;
}

.tableALO .item table .startDate {
    display: inline;
    margin-right: 10px;
}
.tableALO .item table .endDate {
    display: inline;
    background: #FFE0E0;
    border-radius: 10px;
    padding: 3px 9px;
}

.tableALO .item table .total {
    font-size: 12px;
    line-height: 14px;
    color: black;
    width: 70px;
}

.tableALO .item table .obiectiv {
    font-size: 12px;
    line-height: 14px;
    color: rgba(65, 21, 224, 1);
    width: 70px;
    
}

.tableALO .item table .etape,.report .etape {
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 177, 10, 1)!important;
    position: relative;
    
}
.tableALO .item table .etape div.value {
    position: absolute;
    right: 0;
    top: 3px;
}
.report .etape div.value {
    position: absolute;
    left: 17px;
    top: 12px;
    color: #008053;
}

.tableALO .item table .etape .progress,.report .etape .progress{
    height: 5px;
    background-color: rgba(243, 243, 243, 0.6);
    border-radius: 10px;
    justify-content: left;
    margin-left: 55px;
}

.tableALO .item table .etape .progress .progress-bar, .report .etape .progress .progress-bar {
    background-color: #05B37F!important;
    border-radius: 10px;
    height: 5px;
}

.tableALO .item table .comision {
    font-size: 12px;
    line-height: 14px;
    color: black;
    width: 70px;
    
}

.tableALO .item table .leadValue {
    color: rgba(65, 21, 224, 1)!important;
}

.tableALO .item table thead th.empty{
    border: 0;
    background: #FFFFFF;
}
.tableALO .item table thead th.green{
    height: 49px;
    padding: 12px 16px;
    text-align: center;
    border: 1px solid #E4E8EE;
    background: rgba(5, 179, 127, 0.1);
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}

.tableALO .item table thead th.green.title{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.tableALO .item .imgTable {
    width: 48px;
    height: 48px;
    background-color: rgba(0, 207, 232, 1);
    border-radius: 50%;
    align-content: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.tableALO .item .imgTable img {
    width: 25px;
}

.tableALO .item .tabLeads .itemTop {
    padding: 16px 0 16px 20px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
}

.tableALO .item .tabLeads .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    position: relative;
    height: 56px;
}

.tableALO .item .tabLeads .separator {
    background: #E5E5F0;
    border-radius: 0px;
    width: 1px;
    height: 52px;
    position: absolute;
    right: 0;
    bottom: 0;
}


.tableALO .item .tabLeads .startDate {
    display: inline;
    margin-right: 10px;
}

.tableALO .item .tabLeads .endDate {
    display: inline;
    background: #FFE0E0;
    border-radius: 10px;
    padding: 3px 9px;
}

.tableALO .item .tabLeads .budget {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 12px;
}

.tableALO .item .tabLeads td {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: black;
    padding: 8px;
}

.tableALO .item .tabLeads td img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 16px;
}

.tableALO .item .tabLeads td  .view {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: inline;
}
.tableALO .item .tabLeads td  .view img {
    width: 12px;
    margin-left: 5px;
}

