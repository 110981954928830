.lead {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.lead:last-child{
    margin-bottom: 0;
}


.lead .leadIcon {
    align-self: center;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    margin-right: 12px;
}

.lead .leadIcon.blue {
    background-color: rgba(0, 207, 232, 0.3);
}

.lead .leadIcon.green {
    background-color: rgba(5, 179, 127, 0.3);
}

.lead .leadIcon.yellow {
    background-color: rgba(255, 177, 10, 0.3);
}

.lead .leadIcon.purple {
    background-color: rgba(65, 21, 224, 0.3);
}

.lead .leadTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-right: 36px;
    margin-top: -4px;
    flex-grow: 1;
}

.lead .leadStartDate {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-right: 20px;
    height: 22px;
    width: 107px;

    justify-content: center;
    align-items: center;
    display: flex;
}
.lead .leadStartDate img{
    height: 15px;
    width: 15px;
    margin-right: 12px;
}

.lead .leadEndDate {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    height: 22px;
    width: 80px;
    background: #FFE0E0;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
}