@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.main {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.main .background {
    width: 100%;
    height: 70%;
    display: flex;
    position: absolute;
    top: 0;
    justify-content: center;
}

.main .login {
    position: absolute;
}

.main .img1,.main .img2,.main .img3,.main .img4 {
    display: flex;
    position: absolute;
}

.main .img1 {
    width: 721px;
    display: flex;
    align-self: center;
}

.main .img2 {
    width: 135px;
    bottom: -103px;
    left: 440px;
}

.main .img3 {
    width: 819px;
    bottom: -103px;
    left: -230px;
}

.main .img4 {
    left: -230px;
    bottom: -103px;
}

.fcontainer {
   align-self: center;
    overflow: hidden;
    width: 402px;
    height: 100%;
    left: 536px;
    top: 267px;
    border-radius: 6px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    display: block;
    padding: 25px;

}

.form-container{
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
}

.form-control:focus {
    background: transparent!important;
}

input:-webkit-autofill {
    background-color: transparent!important;
}

.login_title{
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
}
.login_subtitle {
    font-family: Montserrat;
    color: rgba(80, 81, 97, 1);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.social-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px; 
}

.google-login-button-container {
    display: inline-block;
}

.google-login-button {
    background:#DE5246;
    border-color: #DE5246;
    width: 350px;
    display: block;
    padding: 13px;
    height: fit-content;
    justify-content: center;
    margin-top: 24px;
}


.facebook, .kep-login-facebook.metro{
    width: 250px;
    height: fit-content;
    background: #3B5998;
    box-shadow: 0px 5px 25px 5px rgba(34, 35, 47, 0.04);
    border-radius: 4px!important;
    color: #FFFFFF;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    padding: 13px;

}


.facebook:hover , .kep-login-facebook.metro{
    color: white;
}

.fcontainer .or {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.fcontainer .item {
    margin-right: 0;
}


.fcontainer .item.incorect {
    border: 1px solid #D32E26;
    box-sizing: border-box;
    border-radius: 4px;
}

.fcontainer .inputs .invalid {
    color: rgba(211, 46, 38, 1);
    text-align: end;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}

.fcontainer .inputs {
    position: relative;
    top: 24px;
}

.fcontainer .rememberMe {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: black;
}
.fcontainer .forgotPassword {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(65, 21, 224, 1);
    padding: 0;
    text-align: end;
}

.fcontainer .rememberMe input {
    margin-right: 10px;
    position: relative;
    top: 2px
}

.fcontainer .form-footer {
    margin-top: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}