.financeInvoice .header {
    background: #FFFFFF;
    border: 1px solid #D0DBEA;
    box-sizing: border-box;
    border-radius: 6px;
    height: 102px;
    padding: 0;
    margin: 20px 0;
}

.financeInvoice .header .title {
    background: linear-gradient(180deg, #4115E0 25.01%, rgba(87, 60, 184, 0.6) 100%);
    border-radius: 6px 0px 0px 6px;
    color: white;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.financeInvoice .invoiceTo .title {
    color: black;
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.financeInvoice .header .info, .financeInvoice .invoiceTo .info {
    color: rgba(34, 35, 47, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.financeInvoice .header .info div, .financeInvoice .invoiceTo .info div{
    display: inline-flex;
    text-align: left;
    align-items: flex-start;
    margin: 20px 20px 0 0 ;
}


.financeInvoice .header .info div img, .financeInvoice .invoiceTo .info div img {
    width: 15px;
    height: 100%;
    margin-right: 18px;
}

.financeInvoice .invoiceTo {
    margin-bottom: 44px;
}

.financeInvoice .invoiceTo .info div .invoice, .financeInvoice .invoiceTo .info div .date {
    margin: 0 24px 0 0 ; 
    color: rgba(65, 21, 224, 1);
}

.financeInvoice .tasks {
    width: 100%;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

table.tasks thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 12px 16px;
    width: 16%;
    text-align: center;
}


table.tasks tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 12px 16px;
    text-align: center;
    width: 16%;
}

table.tasks tbody td img{
    margin-right: 4px;
}

table.tasks td.dat {
    color: rgba(65, 21, 224, 1);
}
table.tasks td.obi {
    color: rgba(255, 177, 10, 1);
}


.financeInvoice .printButton{
    float: right;
}

.financeInvoice .printButton img{
    margin-right: 10px;
}