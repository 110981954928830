
.profileUserMenu {
    margin-top: 32px;
}

.profileUserMenu .tab {
    background: #F3F3F3;
    border-radius: 4px 4px 0px 0px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: black;
    padding: 10px 18px;
}
.profileUserMenu a.active .tab{
    background: white;
}

.profileUserMenu  a{
    text-decoration: none;
}
