.table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

table.company {
    margin-bottom: 48px;
    width: 100%;
}

table.company thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 12px 16px;
    width: 16%;
    text-align: center;
}


table.company tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 12px 16px;
    text-align: center;
    width: 16%;
}

table.company tbody td img{
    margin-right: 4px;
}

table.company td.dat {
    color: rgba(65, 21, 224, 1);
}
table.company td.obi {
    color: rgba(255, 177, 10, 1);
}

.financeDescription .printButton{
    float: right;
}

.financeDescription .printButton img{
    margin-right: 10px;
}

