.puExperience .activityDom{
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
}

.puExperience .activityDom .activityDomItem{
    border-top: 1px solid #E4E8EE;
    margin-right: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #22232F;
    height: 44px;
    align-items: center;
    display: flex;
}

.puExperience .activityDom .activityDomItem img {
    margin-right: 12px;
}


.tabContent .activity {
    margin-top: 20px;
}



.tabContent .activity .activityDom .itemActivity {
    margin-bottom: 26px;
}

.tabContent .activity .activityDom .itemActivity .itemImg {
    border-radius: 26px;
    height: 48px;
    width: 48px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.tabContent .activity .activityDom .itemActivity .itemImg img {
    width: 18px;
}

.tabContent .activity .activityDom .itemActivity.purple .itemImg {
    background: rgba(65, 21, 224, 0.12);
}
.tabContent .activity .activityDom .itemActivity.green .itemImg {
    background: rgba(5, 179, 127, 0.12);;
}
.tabContent .activity .activityDom .itemActivity.yellow .itemImg {
    background: rgba(255, 177, 10, 0.12);;
}
.tabContent .activity .activityDom .itemActivity.blue .itemImg {
    background: rgba(0, 207, 232, 0.12);
}

.tabContent .activity .activityDom .itemActivity .title{
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    margin-bottom: 12px;
}
.tabContent .activity .activityDom .itemActivity .activityProgress{
    display: inline-flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    float: right;
}

.tabContent .activity .activityDom .itemActivity .progress{
    height: 5px;
    background-color: rgba(243, 243, 243, 0.6);
    border-radius: 10px;

}

.tabContent .activity .activityDom .itemActivity.purple .progress .progress-bar{
    background-color: #4115E0!important;
    border-radius: 10px;

}
.tabContent .activity .activityDom .itemActivity.green .progress .progress-bar{
    background-color: #05B37F!important;
    border-radius: 10px;

}
.tabContent .activity .activityDom .itemActivity.yellow .progress .progress-bar{
    background-color: #FFB10A!important;
    border-radius: 10px;

}
.tabContent .activity .activityDom .itemActivity.blue .progress .progress-bar{
    background-color: #00CFE8!important;
    border-radius: 10px;

}
