.leads .mainTitle {
    padding: 0;
    margin: 0;
}

.leads .mainTitle div{
    margin-bottom: 32px;
    display: inline-flex;
}

.leads .mainTitle  div:last-child{
    float: unset;
}

.leads .mainTitle a img{
    margin-right: 10px;
}

.leads .list {
    padding: 0;
    margin: 0;
}

.leads .filters{
    margin-bottom: 8px;
    align-items: center;
    display: inline-flex;
    float: unset;
}

.leads .filters .item {
    width: 196px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    user-select: none;
}

.leads .filters a{
    text-decoration: none;
    color: rgba(80, 81, 97, 1);
}

.leads .filters .item img {
    width: 12px;
    margin-right: 12px;
}

