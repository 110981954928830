@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/*
buttons
*/
.btn {
    font-family: Montserrat;
    
}

.btn-primary {
    background: #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.25);
    border-radius: 4px;
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    border-color: #4115E0;
}

.btn-primary:hover,.btn-primary:active,.btn-primary:focus-visible,.btn-primary:focus {
    color: #fff;
    background: #4115E0;
    border-color: #4115E0;
    outline: none;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.25)!important;
}

.btn-secondary {
    background: #FFFFFF;
    color: #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15);
    border-radius: 4px;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus-visible, .btn-secondary:focus {
    background: #FFFFFF;
    color: #4115E0;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    outline: none;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15)!important;
}

.btn-icon {
    margin-right: 10px;
    width: 12px;
    position: relative;
    top: -1px;
}

h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
}


p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
}

.mr-4{
    margin-right: 24px;
}



/*
tabs
*/

.tabContent {
    background-color: white;
    padding: 20px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 0 6px 6px 6px ;
}


/*
dropzone
*/

.dropzone{
    background: #FFFFFF;
    border: 1px dashed #E4E8EE;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 16px;
    cursor: pointer;
}

.dropzone img {
    margin-right: 20px;
}

.dropzone .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 240px;
    text-align: center;
}
.dropzone .title span{
    font-weight: 600;
    color: #4115E0;
}

.droppedImage{
    min-width: 100%!important;
    height: 100%;
    overflow: hidden;
}

.droppedImage img {
    display: block;
    width: 92px;
}

.thumb {
    display: inline-block;
    border-radius: 2px;
    margin-bottom: 24px;
    margin-right: 40px;
    width: 100px;
    height: 100px;
    padding: 4px;
    position: relative;
    
}

.thumbsContainer{
    margin-top: 16px;
}

.videoPreview, .pdfPreview{    
    display: flex;
    flex-grow: 1;
    height: 100%;
    background-color: rgba(65, 21, 224, 0.1);
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    
}

.pdfPreview{ 
    background-color: rgba(255, 177, 10, 0.1);
}

.videoPreview img, .pdfPreview img {
    width: 20px;
    height: 20px;
    align-self: center;
}

.thumb .deleteIcon {
    position: absolute;
    top: 0;
    right: -14px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.item{
    margin-right: 26px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(185, 185, 195, 0.2));
    z-index: 1;
    margin-bottom: 24px;
}

.item span:first-child{
    margin-top: 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #9A9FA7;
    display: flex;
    align-items: center;
    padding-left: 16px;
    z-index: 100000;
}

.item select, .item input:first-of-type {
    border: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #22232F;
    width: 100%;
    height: 100%;
    padding: 22px 20px 8px 16px;
    background-color: transparent!important;
    position: absolute;
    top: 0;
    -webkit-appearance: none;
}
.item select:focus-visible, .item input:focus-visible{
    outline: none;
}

.item select::-ms-expand{
    display: none;
}


.customDropdown .dropdown-item{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(80, 81, 97, 1);
    
}

.customDropdown .dropdown-item:hover , .customDropdown .dropdown-item:active {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(65, 21, 224, 1);
    background-color: rgba(65, 21, 224, 0.1);
}

.btn-check:active+.btn-info, .btn-check:checked+.btn-info, .btn-info.active, .btn-info:active, .show>.btn-info.dropdown-toggle{
    background-color: transparent;
    border-color: transparent;
}

.customDropdown .btn, .customDropdown .btn:focus, .customDropdown .btn:focus-within{
    background-color: transparent;
    border: 0;
    padding: 0;
    box-shadow: 0 0 0 0.25rem transparent !important;
}

/*
Custom Checkboxes
*/

.containerCheckbox {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 32px;
    margin-right: 32px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 18px;
    width: 18px;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #eee;
    width: 18px;
    height: 18px;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.05));
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.containerCheckbox:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked ~ .checkmark {
    background-color: rgba(65, 21, 224, 1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked ~ .checkmark img{
    display: block;
}

.containerCheckbox .checkmark img {
    left: 3px;
    top: 4px;
    position: relative;
    width: 12px;
    display: none;
}



/*
pagination
*/

ul.react-paginate {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 28px!important;

}

.react-paginate li {
    display: inline-flex;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 4px;
    width: 39px;
    height: 32px;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    margin-right: 12px;
    user-select: none;
}

.react-paginate li.selected {
    background: #4115E0;
    color: white;
}

.react-paginate li.disabled img {
    opacity: 0.5;
}



/* DATE picker*/

.react-datetime-picker {
    top: -2px;
    width: 100%;
    left: 14px;
}
.react-datetime-picker div{
    border: none;
}

.react-datetime-picker .react-datetime-picker__clear-button, .react-datetime-picker .react-datetime-picker__calendar-button {
    display: none;
}

.contactInfo .element.links .icon {
    width: 40px;
}

.contactInfo .element.links .icon.linkedin {
    width: 50px;
}

.setFilters {
    height: 48px;
}

.companyHeader .video{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    top:0;
    left: 0;
    
}

.companyHeader .video .videoPr {
   align-self: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
}

.companyHeader .video .videoPr button {
    display: flex;
    align-self: flex-start;
}
