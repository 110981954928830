.viewOpCard{
    position: relative;
    margin: 0 0px;
}

.viewOpCard {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 2px 5px 11px rgba(31, 72, 72, 0.13);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.viewOpCard .info{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.viewOpCard .info .logoCont {
    width: 144px;
    height: 144px;
    margin-bottom: 14px;
    border-radius: 50%;
}

.viewOpCard .info .username{
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 10px;
}

.viewOpCard .info a{
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-decoration: none;
    color: rgba(34, 35, 47, 1);
}

.viewOpCard .info a img{
    width: 12px;
    margin-left: 8px;
}

.viewOpCard .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: black;
    margin-bottom: 12px;

}

.viewOpCard .date {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-content: center;
    align-items: center;
    color: rgba(80, 81, 97, 1);
    margin-bottom: 8px;
}

.viewOpCard .date img{
    width: 15px;
    margin-right: 10px;
}

.viewOpCard .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #505161;
    margin-bottom: 20px;
    margin-right: 48px;

}


.viewOpCard .separator {
    background: linear-gradient(0deg, rgba(228, 232, 238, 0) 0%, #E4E8EE 52.08%, rgba(228, 232, 238, 0) 100%);
    position: absolute;
    right: 0;
    top: 0;
    height: 216px;
    width: 1px;
}

.viewOpCard .opButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-end;

}

.viewOpCard .opButtons .btn {
    align-self: flex-end;
    margin-bottom: 16px;
}

.viewOpCard button{
    width: 128px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    align-self: center;
    justify-self: flex-end;

}

.viewOpCard .btn-primary a{
    text-decoration: none;
    color: white;
}

.viewOpCard .btn-secondary a{
    text-decoration: none;
    color: rgba(65, 21, 224, 1);
}

.viewOpCard button img {
    margin-right: 10px;
}

.viewOpCard .collaboration {
    width: 200px;
    margin-right: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 11px 0;
    box-shadow: 0px 4px 4px  rgba(115, 103, 240, 0.1);
    color: rgba(0, 0, 0, 1);
    text-align: center;
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
}

.viewOpCard .manager {
    width: 400px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 1);
    padding: 11px 0;
    background: #f1effd;
    box-shadow: 0px 4px 4px rgba(115, 103, 240, 0.1);
    border-radius: 4px;
    text-align: center;
}