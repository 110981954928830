.financeTask .title{
    margin-bottom: 24px;
}

.financeTask .title h2:last-child{
    float: right
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

table.tasks thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 12px 16px;
    width: 16%;
    text-align: center;
}


table.tasks tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 12px 16px;
    text-align: center;
    width: 16%;
    align-items: center;
}

table.tasks tbody td .containerCheckbox{
    margin: 0;
}
table.tasks tbody td .containerCheckbox .checkmark{
    top: -12px;
    left: 5px;}

table.tasks tbody td.denumire,  table.tasks thead th.denumire {
    width: 70%;
    text-align: left;
}

table.tasks tbody td div{
    display: flex;
    align-items: center;
    justify-content: center;
}

table.tasks tbody td .units{
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

table.tasks tbody td .updateUnits{
    color: rgba(80, 81, 97, 1);
    border: 0;
    background: none;
    font-weight: 600;
    font-size: 18px;
}


