.collaboration .title{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.collaboration .filters{
    margin-bottom: 8px;
    align-items: center;
    display: inline-flex;
}

.collaboration .filters .item {
    width: 196px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    user-select: none;
}


.collaboration .filters .item img {
    width: 12px;
    margin-right: 12px;
}

.collaboration .filters .item.checkbox{
    display: inline-flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.collaboration .filters .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.collaboration .filters .favourite {
    width: 32px;
    height: 32px;
    margin-bottom: 24px;
    background: #FFFFFF;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.05);
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}
.collaboration .filters .favourite.checked {
    border: 0.5px solid #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15);
}
