.viewLeadContent {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin: 0;
    padding: 20px;
}


.viewLeadContent .viewOpCard {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
}
.viewLead .mainTitle{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.viewLead .filters{
    margin-bottom: 8px;
    align-items: center;
    display: inline-flex;
}

.viewLead .filters .item {
    width: 196px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    user-select: none;
}

.viewLead .filters a{
    text-decoration: none;
    color: rgba(80, 81, 97, 1);
}

.viewLead .filters .item img {
    width: 12px;
    margin-right: 12px;
}

.viewLead .filters .item.checkbox{
    display: inline-flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.viewLead .filters .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.viewLead .description h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: black;
    margin-bottom: 20px;
}

.viewLeadContent .description {
    margin-top: 34px;
}

.viewLead .description div {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: rgba(80, 81, 97, 1);
    margin-right: 200px;
}

.viewLead .files {
    padding: 20px;
}

.viewLead .files .mainTitle {
    margin-top: 48px;
    margin-bottom: 38px;
}

.viewLead .files .addFile {
    display: inline-block;
    position: absolute;
    right: 48px;
}


.viewLead .files .access {
    display: inline-block;
    align-content: center;
    right: 32px;
    background-color: #05b37f;
    border-color: #05b37f;
    box-shadow: 0px 2px 5px rgba(5, 179, 127, 0.25);
    border-radius: 4px;;
    width: 300px;
    margin-top: 20px;
}



.viewLead .files .addFile img{
    margin-right: 10px;
}

.viewLead .files .saveLead ,.viewLead .infoTask .createTask {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    border-top: 1px solid #E4E8EE;
    padding-top: 20px;
    padding-left: 20px;
}
.viewLead .files .saveLead .btn,.viewLead .infoTask .createTask  .btn{
    width: 230px;
    height: 40px;
}

.viewLead .opButtons .back a {
    color: rgba(5, 179, 127, 1)!important;
}