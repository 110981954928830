.financeHeader{
    position: relative;
}

.financeHeader .arrowLeft, .financeHeader .arrowRight{
    position: absolute;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.financeTitle {
    margin-bottom: 34px;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
}

.financeHeader .arrowLeft{
    left: -15px;
    top: 40%;
}

.financeHeader .arrowRight{
    right: -15px;
    top: 40%;
}

.financeHeader {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 2px 5px 11px rgba(31, 72, 72, 0.13);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.financeHeader .logoCont{
    width: 98px;
    height: 98px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.12);
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 24px;
    font-weight: 700;
    margin: auto;
}

.financeHeader .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: black;
    margin-bottom: 12px;

}
.financeHeader .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #505161;
    margin-bottom: 20px;

}

.financeHeader .attrIcon {
    height: 15px;
    margin-right: 8px;
    position: relative;
    top: -2px;
}


.financeHeader button{
    width: 128px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    float: right;
    justify-content: center;
    align-self: center;
}

.financeHeader button img {
    margin-right: 10px;
}