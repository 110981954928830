.contractCard {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    flex-direction: column;
    margin-right: 28px;
    margin-bottom: 20px;
}

.contractCard .imgLeft{
    position: absolute;
    left: 0;
    top: 0;

}

.contractCard .imgRight{
    position: absolute;
    right: 0;
    top: 42px;

}

.contractCard .profileImage {
    width: 150px;
    height: 150px;
    margin-top: 32px;
    margin-bottom: 16px;
}

.contractCard .profileImage img {
    width: 150px;
    height: 150px;
}

.contractCard .name{
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 10px;
}

.contractCard .contractUnsign , .contractCard .contractSign {
    width: 228px;
    height: 58px;
    box-sizing: border-box;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(34, 35, 47, 1);
    margin-bottom: 16px;
    padding: 8px;
}


.contractCard .contractUnsign div:first-child, .contractCard .contractSign div:first-child{
    margin-bottom: 6px;
}

.contractCard .contractUnsign img, .contractCard .contractSign img,.contractCard .contractUnsign div:last-child, .contractCard .contractSign div:last-child{
    float: right;
}

.contractCard .contractUnsign{
    border: 0.5px solid #4115E0;

}

.contractCard .contractSign {
    border: 0.5px solid #05B37F;

} 

.contractCard .contractUnsign div:first-child{
    color: rgba(65, 21, 224, 1);

}

.contractCard .contractSign div:first-child{
    color: rgba(5, 179, 127, 1);
} 



