.collabCard {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    flex-direction: column;
    margin-right: 28px;
    margin-bottom: 20px;
}

a {
    text-decoration: none;
}
.collabCard .imgLeft{
    position: absolute;
    left: 0;
    top: 0;
    
}

.collabCard .imgRight{
    position: absolute;
    right: 0;
    top: 42px;
    
}

.collabCard .profileImage {
    width: 150px;
    height: 150px;
    margin-top: 32px;
    margin-bottom: 16px;
}

.collabCard .profileImage img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
.collabCard .name{
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 10px;
}

.collabCard .domain {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
}
.collabCard .viewProfile {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    position: relative;
    color: black;
}

.collabCard .viewProfile img {
    position: absolute;
    top: -1px;
    height: 18px;
}
.collabCard .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    width: 205px;
    margin-bottom: 16px;
    height: 32px;
    color: rgba(80, 81, 97, 1);
}

.collabCard .message {
    margin-bottom: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.collabCard .message div{
    display: inline-block;
}

.collabCard .btn {
    margin-right: 16px;
    width: 150px;
}
.collabCard .btn.request {
    margin-right: 10px;
    width: 88px;
}

.collabCard .btn.request:first-child {
    margin-left: 16px;
}

.collabCard .btn img{
    margin-right: 10px;
}

.collabCard .message .action {
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.05);
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.collabCard .message .action.heart {
    margin-right: 8px;
}
.collabCard .message .action.heart.active {
    background-color: rgba(65, 21, 224, 0.1);
}