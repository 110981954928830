.tabContent .activityDom{
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
}

.tabContent .activityDom .activityDomItem{
    border-top: 1px solid #E4E8EE;
    margin-right: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #22232F;
    height: 44px;
    align-items: center;
    display: flex;
}

.tabContent .activityDom .activityDomItem img {
    margin-right: 12px;
}