@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
buttons
*/
.btn {
    font-family: Montserrat;
    
}

.btn-primary {
    background: #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.25);
    border-radius: 4px;
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    border-color: #4115E0;
}

.btn-primary:hover,.btn-primary:active,.btn-primary:focus-visible,.btn-primary:focus {
    color: #fff;
    background: #4115E0;
    border-color: #4115E0;
    outline: none;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.25)!important;
}

.btn-secondary {
    background: #FFFFFF;
    color: #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15);
    border-radius: 4px;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus-visible, .btn-secondary:focus {
    background: #FFFFFF;
    color: #4115E0;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    outline: none;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15)!important;
}

.btn-icon {
    margin-right: 10px;
    width: 12px;
    position: relative;
    top: -1px;
}

h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
}


p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
}

.mr-4{
    margin-right: 24px;
}



/*
tabs
*/

.tabContent {
    background-color: white;
    padding: 20px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 0 6px 6px 6px ;
}


/*
dropzone
*/

.dropzone{
    background: #FFFFFF;
    border: 1px dashed #E4E8EE;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 16px;
    cursor: pointer;
}

.dropzone img {
    margin-right: 20px;
}

.dropzone .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 240px;
    text-align: center;
}
.dropzone .title span{
    font-weight: 600;
    color: #4115E0;
}

.droppedImage{
    min-width: 100%!important;
    height: 100%;
    overflow: hidden;
}

.droppedImage img {
    display: block;
    width: 92px;
}

.thumb {
    display: inline-block;
    border-radius: 2px;
    margin-bottom: 24px;
    margin-right: 40px;
    width: 100px;
    height: 100px;
    padding: 4px;
    position: relative;
    
}

.thumbsContainer{
    margin-top: 16px;
}

.videoPreview, .pdfPreview{    
    display: flex;
    flex-grow: 1;
    height: 100%;
    background-color: rgba(65, 21, 224, 0.1);
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    
}

.pdfPreview{ 
    background-color: rgba(255, 177, 10, 0.1);
}

.videoPreview img, .pdfPreview img {
    width: 20px;
    height: 20px;
    align-self: center;
}

.thumb .deleteIcon {
    position: absolute;
    top: 0;
    right: -14px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.item{
    margin-right: 26px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    position: relative;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(185, 185, 195, 0.2));
            filter: drop-shadow(0px 4px 4px rgba(185, 185, 195, 0.2));
    z-index: 1;
    margin-bottom: 24px;
}

.item span:first-child{
    margin-top: 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #9A9FA7;
    display: flex;
    align-items: center;
    padding-left: 16px;
    z-index: 100000;
}

.item select, .item input:first-of-type {
    border: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #22232F;
    width: 100%;
    height: 100%;
    padding: 22px 20px 8px 16px;
    background-color: transparent!important;
    position: absolute;
    top: 0;
    -webkit-appearance: none;
}
.item select:focus-visible, .item input:focus-visible{
    outline: none;
}

.item select::-ms-expand{
    display: none;
}


.customDropdown .dropdown-item{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(80, 81, 97, 1);
    
}

.customDropdown .dropdown-item:hover , .customDropdown .dropdown-item:active {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(65, 21, 224, 1);
    background-color: rgba(65, 21, 224, 0.1);
}

.btn-check:active+.btn-info, .btn-check:checked+.btn-info, .btn-info.active, .btn-info:active, .show>.btn-info.dropdown-toggle{
    background-color: transparent;
    border-color: transparent;
}

.customDropdown .btn, .customDropdown .btn:focus, .customDropdown .btn:focus-within{
    background-color: transparent;
    border: 0;
    padding: 0;
    box-shadow: 0 0 0 0.25rem transparent !important;
}

/*
Custom Checkboxes
*/

.containerCheckbox {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 32px;
    margin-right: 32px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 18px;
    width: 18px;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #eee;
    width: 18px;
    height: 18px;
    -webkit-filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.05));
            filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.05));
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.containerCheckbox:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked ~ .checkmark {
    background-color: rgba(65, 21, 224, 1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked ~ .checkmark img{
    display: block;
}

.containerCheckbox .checkmark img {
    left: 3px;
    top: 4px;
    position: relative;
    width: 12px;
    display: none;
}



/*
pagination
*/

ul.react-paginate {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 28px!important;

}

.react-paginate li {
    display: inline-flex;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 4px;
    width: 39px;
    height: 32px;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    margin-right: 12px;
    -webkit-user-select: none;
            user-select: none;
}

.react-paginate li.selected {
    background: #4115E0;
    color: white;
}

.react-paginate li.disabled img {
    opacity: 0.5;
}



/* DATE picker*/

.react-datetime-picker {
    top: -2px;
    width: 100%;
    left: 14px;
}
.react-datetime-picker div{
    border: none;
}

.react-datetime-picker .react-datetime-picker__clear-button, .react-datetime-picker .react-datetime-picker__calendar-button {
    display: none;
}

.contactInfo .element.links .icon {
    width: 40px;
}

.contactInfo .element.links .icon.linkedin {
    width: 50px;
}

.setFilters {
    height: 48px;
}

.companyHeader .video{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    top:0;
    left: 0;
    
}

.companyHeader .video .videoPr {
   align-self: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
}

.companyHeader .video .videoPr button {
    display: flex;
    align-self: flex-start;
}

/* === GLOBAL STYLES === */
* {
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
}

.bg-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* === HEADER STYLES === */
header {
    text-align: center;
    padding: 20px 0;
    background-color: #fff;
    border-radius: 5px;
}

header .logos img {
    margin: 0 10px;
    width: 150px;
}

header p {
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    color: #5d446e;
}

/* === NAVIGATION STYLES === */
.nav {
    height: 50px;
    width: 100%;
    position: relative;
}

.nav > .nav-header {
    display: inline;
}

.nav > .nav-header > .nav-title {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 10px;
}

.nav-title img {
    max-width: 45%;
    height: auto;
    vertical-align: middle;
}

.nav > .nav-btn {
    display: none;
}

.nav > .nav-links {
    right: 0;
    position: absolute;
    top: 0;
    font-weight: bold;
}

.nav > .nav-links > a {
    display: inline-block;
    padding: 13px 10px;
    text-decoration: none;
    color: #5d446e;
}

.nav > .nav-links > a:hover {
    background-color: rgba(0, 150, 136, 0.3);
}

.nav > #nav-check {
    display: none;
}

.language-dropdown {
    position: relative;
    display: inline-block;
}

.language-dropdown button {
    padding: 13px 10px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    color: #5d446e;
    background-color: transparent;
}

.language-dropdown:hover button {
    background-color: rgba(0, 150, 136, 0.3);
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    background-color: rgba(0, 150, 136, 0.3);
    z-index: 1;
}

.dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 18px;
}

.language-dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a:hover {
    background-color: rgba(0, 150, 136, 0.3);
}

/* === TWO COLUMN STYLES === */
.two-column {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    flex: 0.5 1;
    color: #5d446e;
}

.text-content h2 {
    margin-left: 0;
    margin-top: 0;
}

.text-content p {
    font-size: 18px;
    line-height: 1.8;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.image-content {
    flex: 1 1;
    max-width: 50%;
}

.image-content img {
    max-width: 90%;
    height: auto;
    display: block;
    margin: 0 auto;
    align-items: center;
}

/* === TWO COLUMN 2/1 STYLES === */
.two-column-section1 {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 50px;
}

.image-column1 {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-column1 img {
    max-width: 90%;
    height: auto;
}

.text-column {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: right;
}

.text-column p {
    font-size: 18px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #5d446e;
    line-height: 1.8;
    width: 100%;
}

/* === SECOND TWO-COLUMN SECTION === */
.two-column-section2 {
    display: flex;
    grid-gap: 20 px;
    gap: 20 px;
    margin-bottom: 50px;
}

.image-column2 {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-column2 img {
    max-width: 70%;
    height: auto;
}

.text-column2 {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.text-column2 p {
    width: auto;
    font-size: 18px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #5d446e;
    line-height: 1.8;
}

/* === CARD SECTION STYLES === */
.card-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;
    grid-gap: 30px;
    gap: 30px;
}

.card {
    flex: 1 1;
    max-width: 45%;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(74, 153, 210, 0.25);
}

.card img {
    max-width: 30%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.card h1 {
    font-size: 24px;
    font-weight: bold;
    color: #5d446e;
    margin-bottom: 10px;
    text-align: center;
}

.card p {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 16px;
    line-height: 1.5;
    color: #5d446e;
    padding: 0 10px;
    text-align: center;
}

/* === FOOTER STYLES === */
footer {
    text-align: center;
    padding: 20px 0;
    color: #5d446e;
    border-radius: 5px;
}

footer .newsletter {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    margin: 10px 0;
}

footer .newsletter input, footer .newsletter button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
}

footer .newsletter button {
    background-color: #e67e22;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

footer .newsletter button:hover {
    background-color: #d35400;
}

footer .footer-logos img {
    margin: 0 10px;
    width: 180px;
}

footer .social-icons img {
    width: 40px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {

    /* Navbar Mobile Styles */
    .nav > .nav-btn {
        display: inline-block;
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .nav > .nav-btn > label {
        display: inline-block;
        width: 50px;
        height: 50px;
        padding: 13px;
    }

    .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
        background-color: rgba(0, 150, 136, 0.3);
    }

    .nav > .nav-btn > label > span {
        display: block;
        width: 25px;
        height: 10px;
        border-top: 2px solid #5d446e;
    }

    .nav > .nav-links {
        position: absolute;
        display: block;
        width: 100%;
        background-color: #e2eff8;
        height: 0px;
        transition: all 0.3s ease-in;
        overflow-y: hidden;
        top: 50px;
        left: 0px;
    }
      
    .nav > .nav-links > a {
        display: block;
        width: 100%;
      }

    .nav > #nav-check:not(:checked) ~ .nav-links {
        height: 0px;
    }

    .nav > #nav-check:checked ~ .nav-links {
        height: calc(100vh - 50px);
        overflow-y: auto;
    }

    .two-column, .three-column, .card-section {
        flex-direction: column;
        align-items: center;
    }

    .image-content {
        max-width: 100%; /* Allow image container to take up full width on smaller screens */
    }

    .two-column-section1 {
        flex-direction: column; /* Stack columns vertically with image below text */
    }

     .two-column-section2 {
        flex-direction: column-reverse; /* Stack columns vertically with image below text */
    }

    .card {
        max-width: 100%; /* Cards will take the full width on mobile */
        padding: 15px; /* Slightly reduce padding to give more space to the content */
    }

    .card p {
        font-size: 18px; /* Slightly increasing the font size for better readability on mobile */
        padding: 0 5px; /* Adjusting the horizontal padding for the paragraph */
    }

}



.notification .separator {
    border: 0.5px solid #E4E8EE;
    margin: 12px 0;
}

.notification .itemNot {

    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;
    padding-right: 0;
    width: 44px;
    height: 32px;
}

.notification .itemImg {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.notification .itemImg img {

    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
}

.notification .itemImg.purple {
    background-color: rgba(65, 21, 224, 0.1);;
}
.notification .itemImg.yellow {
    background-color: rgba(255, 177, 10, 0.1);;
}
.notification .itemImg.green {
    background-color: rgba(5, 179, 127, 0.1);;
}
.notification .itemImg.blue {
    background-color: rgba(0, 207, 232, 0.1);;
}

.notification .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: black;
}
.notification .desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(154, 159, 167, 1);
}
.notification .date {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(154, 159, 167, 1);
    text-align: end;
}
body{
    background-color: #E5E5E5;
    font-family: Montserrat;
}

.sideMenu{
    background: #FFFFFF;
    box-shadow: 0px 5px 11px rgba(31, 72, 72, 0.13);
    border-radius: 6px;
    min-height: 100vh;
}

.sideMenu .title{
    margin-top: 32px;
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: rgba(115, 103, 240, 1);
    justify-content: center;
    display: flex;
}

.sideMenu .currentPath {
    max-width: 240px;
    height: 42px;
    background: #F8F8F8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.sideMenu .currentPath img:first-child{
    margin-left: 10px;
}

.sideMenu .currentPath .badge{
    background-color: #7367F0;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    display: inline-block;
    margin-left: 10px;
    margin-right: 2px;
}

.sideMenu .opBadge.badge{
    color: white;
    background-color: #aba4f6!important;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    display: inline-block;
    position: absolute;
    right: 0;
}

.sideMenu .opBadge.badge text {
    position: absolute;
    left: 0;
}

.sideMenu a{
    text-decoration: none;
    padding: 0;
}

.sideMenu div{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(34, 35, 47, 1);
    padding: 0 10px;
    position: relative;
}
.sideMenu a{
    padding: 0 10px;
}

.sideMenu div img{
    margin-right: 12px;
    width: 17px;
    height: 17px;
}

.sideMenu .opportunityTitle{
    margin: 48px 0 0 0;
}

.pageContent {
    padding: 0 28px 28px 18px;
}



.header{
    margin-top: 24px;
    margin-bottom: 32px;
    background: #FFFFFF;
    border-radius: 6px;
    height: 62px;
    padding: 0 13px;
    display: flex;
    flex-direction: row;
}

.header .notifications{
    display: flex;
    height: 100%;
    align-items: center;
}

.header .icon{
    width: 18px;
    height: 18px;
    margin: 0px 7px;
}

.header .itemHeader{
    position: relative;
}

.header .notifications .badge{
    background-color: #7367F0;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    position: absolute;
    right: -2px;
    top: -8px;

}

.header .search {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    align-content: center;
}
@media (max-width: 730px) {
    .header .search {
        display: none;
    }
}

.header .search .cont {
    margin-left: auto;
    position: relative;
}

.header .search .cont img {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 15px;
}

.header .search .cont input {
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 6px;
    width: 283px;
    height: 36px;
    padding: 10px 18px;
}

.header .search .cont input::-webkit-input-placeholder{
    color: #9A9FA7;
}

.header .search .cont input::placeholder{
    color: #9A9FA7;
}

.header .account {
    align-items: center;
    display: flex;
}
@media (max-width: 465px) {
    .header .account {
        display: none;
    }
}
.header .account .language .flag {
    height: 24px;
}

.header .account .btn {
    background-color: transparent;
    color: #505161;
    font-weight: 500;
    font-size: 16px;
    border: 0;
}

.header .btn .title {
    margin: 0 10px 0 7px;
    display: inline-block;
}
.header .dropdown .btn-info, .report .dropdown .btn-info {
    background-color: transparent;
    border: 0!important;
    box-shadow: none!important;
}

.header .dropdown-toggle::after, .report .dropdown-toggle::after {
    display: none;
    border: 0;
}

.header .dropdown-menu, .report .dropdown-menu {
    padding: 12px 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 316px;
}

.header .usersList{
    width: 283px;
    position: absolute;
    top: 51px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #E4E8EE;
    right: 0;
    z-index: 10000000000;
}

.header .dropdown-menu.language, .report {
    width: 110px;
    min-width: 90px;
    -webkit-transform: translate(0px, 37px)!important;
            transform: translate(0px, 37px)!important;
}

.header .notifications .dropdown .title{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: black;
    display: inline-block;
}

.header .notifications .dropdown .markRead{
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 207, 232, 1);
    display: inline-block;
    float: right;
    position: relative;
    top: 5px;
    
}

.sideMenu .title img {
    height: 50px;
    width: unset;
}

.sideMenu .containerLink {
    display: inline-flex;
    width: calc(100% - 10px);
    padding-left: 0;
}



.companyMenu {
    margin-top: 32px;
}

.companyMenu .tab {
    background: #F3F3F3;
    border-radius: 4px 4px 0px 0px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: black;
    padding: 10px 18px;
}
.companyMenu a.active .tab{
    background: white;
}

.companyMenu  a{
    text-decoration: none;
}



.opportunity {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 2px 5px 11px rgba(31, 72, 72, 0.13);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.opportunity .logoCont{
    width: 98px;
    height: 98px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.12);
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 24px;
    font-weight: 700;
    margin: auto;
}

.opportunity .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: black;
    margin-bottom: 12px;
    
}
.opportunity .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #505161;
    margin-bottom: 20px;
    
}

.opportunity .attrIcon {
    width: 14px;
    margin-right: 10px;
    position: relative;
    top: -2px;
}

.opportunity .attrTitle{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #505161;
}
.opportunity .attrVal{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: black;
}

.opportunity .editCol{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.opportunity .editCol .btn {
    max-width: 116px;
}

.opportunity .editOportunity{
    position: absolute;
    top: 0;
    right: 22px;
    height: 18px;
    cursor: pointer;
}
.fileCard {
    min-width: 232px;
    min-height: 260px;
    width: calc(25% - 53px);
    display: inline-block;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-right: 53px;
    margin-bottom: 21px;
    overflow: hidden;
}

.fileCard .imageCont {
    height: 149px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 8px;
    position: relative;
}

.fileCard .imageCont.yellow {
    background-color: rgba(255, 177, 10, 0.1);
}
.fileCard .imageCont.purple {
    background-color: rgba(65, 21, 224, 0.1);
}
.fileCard .imageCont.green {
    background-color: rgba(5, 179, 127, 0.1);
}
.fileCard .imageCont.blue {
    background-color: rgba(10, 93, 255, 0.1);
}

.fileCard .imageCont .actions {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.customDropdown{
    background-color: transparent;
}


.customDropdown .btn::after{
    display: none;
}

.fileCard .title{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 16px;
}

.fileCard .size {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(154, 159, 167, 1);
    margin: 8px 0 8px 16px;
    
}

.fileCard .lastAccessed {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(80, 81, 97, 1);
    margin-left: 16px;
    margin-bottom: 16px;
    
}
.tabContent .activityDom{
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
}

.tabContent .activityDom .activityDomItem{
    border-top: 1px solid #E4E8EE;
    margin-right: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #22232F;
    height: 44px;
    align-items: center;
    display: flex;
}

.tabContent .activityDom .activityDomItem img {
    margin-right: 12px;
}
.lead {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.lead:last-child{
    margin-bottom: 0;
}


.lead .leadIcon {
    align-self: center;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    margin-right: 12px;
}

.lead .leadIcon.blue {
    background-color: rgba(0, 207, 232, 0.3);
}

.lead .leadIcon.green {
    background-color: rgba(5, 179, 127, 0.3);
}

.lead .leadIcon.yellow {
    background-color: rgba(255, 177, 10, 0.3);
}

.lead .leadIcon.purple {
    background-color: rgba(65, 21, 224, 0.3);
}

.lead .leadTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-right: 36px;
    margin-top: -4px;
    flex-grow: 1;
}

.lead .leadStartDate {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-right: 20px;
    height: 22px;
    width: 107px;

    justify-content: center;
    align-items: center;
    display: flex;
}
.lead .leadStartDate img{
    height: 15px;
    width: 15px;
    margin-right: 12px;
}

.lead .leadEndDate {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    height: 22px;
    width: 80px;
    background: #FFE0E0;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.fileContainer img{
    position: absolute;
    top: 0;
    right: 0;
}

.companyFiles .addFile {
    display: inline-block;
    position: absolute;
    right: 20px;
}

.companyFiles .addFile img{
    margin-right: 10px;
}

.companyFiles .uploadFile {
    position: absolute;
    display: inline-flex;
    right: 164px;
    
    height: 40px;
}

.companyFiles .uploadFile input button {
    background: #FFFFFF;
    color: #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15);
    border-radius: 4px;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
}
.companySettings p{
    margin-bottom: 24px;
}


.companySettings h3{
    margin-top: 8px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: black;
}

.companySettings .subtitle{
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: black;
}

.companySettings .companyDescription{
    background: #FFFFFF;
    border: 0.5px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(185, 185, 195, 0.2);
    border-radius: 4px;
    height: 148px;
    display: block;
    width: 100%;
}

.companySettings button {
    width: 230px;
    margin-top: 20px;
}

.companySettings .itemSave {
    border-top: 1px solid #E4E8EE;
    margin-bottom: 16px;
}


.companySettings .item.select {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.companySettings .item.select div {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: unset;
    margin-top: 0;
}

.opportunityEdit h1{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.opportunityEdit h2{
    margin-bottom: 26px;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
}

.opportunityEdit h3{
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}
.opportunityEdit h5{
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

.opportunityEdit .info {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 32px;
}

.opportunityEdit .info .option{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
}

.opportunityEdit .info .option input{
    display: inline-block;
    margin-right: 10px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 2px;

}

.opportunityEdit .info .option input:checked{
    background: red;
}


.opportunityEdit .info .option p {
    display: inline-block;
    margin-right: 35px;

}

.opportunityEdit .desciption{
    background: #FFFFFF;
    border: 0.5px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(185, 185, 195, 0.2);
    border-radius: 4px;
    height: 148px;
    display: block;
    width: 100%;
}


.opportunityEdit .files {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 32px;
}

.opportunityEdit .files .addFile {
    display: inline-block;
    position: absolute;
    right: 32px;
}

.opportunityEdit .files .addFile img{
    margin-right: 10px;
}

.opportunityEdit .files .saveLead ,.opportunityEdit .infoTask .createTask {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    border-top: 1px solid #E4E8EE;
    padding-top: 20px;
    padding-left: 20px;
}
.opportunityEdit .files .saveLead .btn,.opportunityEdit .infoTask .createTask  .btn{
    width: 230px;
    height: 40px;
}

.opportunityEdit .infoTask {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 32px;
}

.opportunityEdit .infoTask .btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-right: 20px;
}

.opportunityEdit .infoTask .btn img {
    margin-right: 10px;
}


/*
Confirm alert
*/
.react-confirm-alert .react-confirm-alert-body{
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: black;
    width: 611px;
}

.react-confirm-alert h1{
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: black;
}
.react-confirm-alert .react-confirm-alert-button-group {
    position: relative;
    justify-content: flex-end;
}

.react-confirm-alert .react-confirm-alert-button-group button{
    background: #FFFFFF;
    color: #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15);
    border-radius: 4px;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
}

.react-confirm-alert .react-confirm-alert-button-group button:last-child{
    background: #4115E0;
    color: white;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.25);
    border-radius: 4px;
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    border-color: #4115E0;
}







.loEdit h1{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.loEdit h2{
    margin-bottom: 26px;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
}

.loEdit h3{
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}
.loEdit h5{
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

.loEdit .info {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 32px;
}

.loEdit .info .option{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
}

.loEdit .info .option input{
    display: inline-block;
    margin-right: 10px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 2px;

}

.loEdit .info .option input:checked{
    background: red;
}


.loEdit .info .option p {
    display: inline-block;
    margin-right: 35px;

}

.loEdit .desciption{
    background: #FFFFFF;
    border: 0.5px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(185, 185, 195, 0.2);
    border-radius: 4px;
    height: 148px;
    display: block;
    width: 100%;
}


.loEdit .files {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 32px;
}

.loEdit .files .addFile {
    display: inline-block;
    position: absolute;
    right: 58px;
}

.loEdit .files .addFile img{
    margin-right: 10px;
}

.loEdit .files .saveLead ,.loEdit .infoTask .createTask {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    border-top: 1px solid #E4E8EE;
    padding-top: 20px;
    padding-left: 20px;
}
.loEdit .files .saveLead .btn,.loEdit .infoTask .createTask  .btn{
    width: 230px;
    height: 40px;
}

.loEdit .infoTask {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 32px;
}

.loEdit .infoTask .btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-right: 20px;
}

.loEdit .infoTask .btn img {
    margin-right: 10px;
}

.loEdit .form-container {
    background: transparent!important;
}

.saveFiles Ă


/*
Confirm alert
*/
.react-confirm-alert .react-confirm-alert-body{
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: black;
    width: 611px;
}

.react-confirm-alert h1{
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: black;
}
.react-confirm-alert .react-confirm-alert-button-group {
    position: relative;
    justify-content: flex-end;
}

.react-confirm-alert .react-confirm-alert-button-group button{
    background: #FFFFFF;
    color: #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15);
    border-radius: 4px;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
}

.react-confirm-alert .react-confirm-alert-button-group button:last-child{
    background: #4115E0;
    color: white;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.25);
    border-radius: 4px;
    height: 40px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    border-color: #4115E0;
}






.collaboration .title{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.collaboration .filters{
    margin-bottom: 8px;
    align-items: center;
    display: inline-flex;
}

.collaboration .filters .item {
    width: 196px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
            user-select: none;
}


.collaboration .filters .item img {
    width: 12px;
    margin-right: 12px;
}

.collaboration .filters .item.checkbox{
    display: inline-flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.collaboration .filters .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.collaboration .filters .favourite {
    width: 32px;
    height: 32px;
    margin-bottom: 24px;
    background: #FFFFFF;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.05);
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
}
.collaboration .filters .favourite.checked {
    border: 0.5px solid #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15);
}

.contractCard {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    flex-direction: column;
    margin-right: 28px;
    margin-bottom: 20px;
}

.contractCard .imgLeft{
    position: absolute;
    left: 0;
    top: 0;

}

.contractCard .imgRight{
    position: absolute;
    right: 0;
    top: 42px;

}

.contractCard .profileImage {
    width: 150px;
    height: 150px;
    margin-top: 32px;
    margin-bottom: 16px;
}

.contractCard .profileImage img {
    width: 150px;
    height: 150px;
}

.contractCard .name{
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 10px;
}

.contractCard .contractUnsign , .contractCard .contractSign {
    width: 228px;
    height: 58px;
    box-sizing: border-box;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(34, 35, 47, 1);
    margin-bottom: 16px;
    padding: 8px;
}


.contractCard .contractUnsign div:first-child, .contractCard .contractSign div:first-child{
    margin-bottom: 6px;
}

.contractCard .contractUnsign img, .contractCard .contractSign img,.contractCard .contractUnsign div:last-child, .contractCard .contractSign div:last-child{
    float: right;
}

.contractCard .contractUnsign{
    border: 0.5px solid #4115E0;

}

.contractCard .contractSign {
    border: 0.5px solid #05B37F;

} 

.contractCard .contractUnsign div:first-child{
    color: rgba(65, 21, 224, 1);

}

.contractCard .contractSign div:first-child{
    color: rgba(5, 179, 127, 1);
} 




.finance .printButton{
    float: right;
}

.finance .printButton img{
    margin-right: 10px;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

table.sellers thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 12px 16px;
    width: 16%;
    text-align: center;
}


table.sellers tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 12px 16px;
    text-align: center;
    width: 16%;
}

table.sellers tbody td img{
    margin-right: 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

table.sellers tbody td input {
    outline: none;
    height: 39px;
    border: 0;
}

table.sellers td.name,table.sellers th.name {
    text-align: left;
    width: 20%;
    padding: 8px 16px;
}
table.sellers td.dat {
    color: rgba(65, 21, 224, 1);
}
table.sellers td.obi {
    color: rgba(255, 177, 10, 1);
}


table.sellers thead th.empty{
    border: 0;
    background: #FFFFFF;
}
table.sellers thead th.green{
    color: rgba(5, 179, 127, 1);
    border: 1px solid #E4E8EE;
    background: rgba(5, 179, 127, 0.1);
}

table.sellers thead th.green div {
    flex-grow: 1;
    align-items: center;
    width: 100%;
    align-self: center;
}

table.sellers thead th.green .info{
    width: 50%;
    height: 100%;
    display: inline-block;
}



.dateSelector {
    background: #FFFFFF;
    border: 0.5px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 265px;
    height: 40px;
    display: inline-flex;
    margin-right: 20px;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
}

.dateSelector .value{
    flex-grow: 1;
    text-align: center;
    padding: 0 10px;
    -webkit-user-select: none;
            user-select: none;
}

.dateSelector .action {
    padding: 0 25px ;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}


.table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

table.company {
    margin-bottom: 48px;
    width: 100%;
}

table.company thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 12px 16px;
    width: 16%;
    text-align: center;
}


table.company tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 12px 16px;
    text-align: center;
    width: 16%;
}

table.company tbody td img{
    margin-right: 4px;
}

table.company td.dat {
    color: rgba(65, 21, 224, 1);
}
table.company td.obi {
    color: rgba(255, 177, 10, 1);
}

.financeDescription .printButton{
    float: right;
}

.financeDescription .printButton img{
    margin-right: 10px;
}


.FinanceMenu {
    margin-top: 32px;
}

.FinanceMenu .tab {
    background: #F3F3F3;
    border-radius: 4px 4px 0px 0px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: black;
    padding: 10px 18px;
}
.FinanceMenu a.active .tab{
    background: white;
}

.FinanceMenu  a{
    text-decoration: none;
}

.financeTask .title{
    margin-bottom: 24px;
}

.financeTask .title h2:last-child{
    float: right
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

table.tasks thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 12px 16px;
    width: 16%;
    text-align: center;
}


table.tasks tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 12px 16px;
    text-align: center;
    width: 16%;
    align-items: center;
}

table.tasks tbody td .containerCheckbox{
    margin: 0;
}
table.tasks tbody td .containerCheckbox .checkmark{
    top: -12px;
    left: 5px;}

table.tasks tbody td.denumire,  table.tasks thead th.denumire {
    width: 70%;
    text-align: left;
}

table.tasks tbody td div{
    display: flex;
    align-items: center;
    justify-content: center;
}

table.tasks tbody td .units{
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

table.tasks tbody td .updateUnits{
    color: rgba(80, 81, 97, 1);
    border: 0;
    background: none;
    font-weight: 600;
    font-size: 18px;
}



.financeHeader{
    position: relative;
}

.financeHeader .arrowLeft, .financeHeader .arrowRight{
    position: absolute;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.financeTitle {
    margin-bottom: 34px;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
}

.financeHeader .arrowLeft{
    left: -15px;
    top: 40%;
}

.financeHeader .arrowRight{
    right: -15px;
    top: 40%;
}

.financeHeader {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 2px 5px 11px rgba(31, 72, 72, 0.13);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.financeHeader .logoCont{
    width: 98px;
    height: 98px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.12);
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 24px;
    font-weight: 700;
    margin: auto;
}

.financeHeader .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: black;
    margin-bottom: 12px;

}
.financeHeader .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #505161;
    margin-bottom: 20px;

}

.financeHeader .attrIcon {
    height: 15px;
    margin-right: 8px;
    position: relative;
    top: -2px;
}


.financeHeader button{
    width: 128px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    float: right;
    justify-content: center;
    align-self: center;
}

.financeHeader button img {
    margin-right: 10px;
}
.leads .mainTitle {
    padding: 0;
    margin: 0;
}

.leads .mainTitle div{
    margin-bottom: 32px;
    display: inline-flex;
}

.leads .mainTitle  div:last-child{
    float: right!important;
}

.leads .mainTitle a img{
    margin-right: 10px;
}

.leads .list {
    padding: 0;
    margin: 0;
}


.leads2{
    position: relative;
}

.leads2 {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 2px 5px 11px rgba(31, 72, 72, 0.13);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.leads2 .logoCont{
    width: 98px;
    height: 98px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.12);
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 24px;
    font-weight: 700;
    margin: auto;
}

.leads2 .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: black;
    margin-bottom: 12px;

}

.leads2 .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #505161;
    margin-bottom: 20px;

}

.leads2 .attrIcon {
    width: 14px;
    margin-right: 10px;
    position: relative;
    top: -2px;
}

.leads2 .attrTitle{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #505161;
}
.leads2 .attrVal{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: black;
}
.leads2 .separator {
    background: linear-gradient(0deg, rgba(228, 232, 238, 0) 0%, #E4E8EE 52.08%, rgba(228, 232, 238, 0) 100%);
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
}

.leads2 button{
    width: 128px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    align-self: center;
    justify-self: flex-end;
    color: rgba(65, 21, 224, 1);
}

.leads2 button a{
    text-decoration: none;
    color: rgba(65, 21, 224, 1);
}

.leads2 button img {
    margin-right: 10px;
}
.myOpportunity {
    padding: 0;
    margin: 0;
}

.myOpportunity .title div{
    margin-bottom: 32px;
    display: inline-flex;
}

.myOpportunity .title {
    padding: 0;
}

.myOpportunity .title a img{
    margin-right: 10px;
}

.myOpportunity .title  div:last-child{
    float: right;
}
.main {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.main .background {
    width: 100%;
    height: 70%;
    display: flex;
    position: absolute;
    top: 0;
    justify-content: center;
}

.main .login {
    position: absolute;
}

.main .img1,.main .img2,.main .img3,.main .img4 {
    display: flex;
    position: absolute;
}

.main .img1 {
    width: 721px;
    display: flex;
    align-self: center;
}

.main .img2 {
    width: 135px;
    bottom: -103px;
    left: 440px;
}

.main .img3 {
    width: 819px;
    bottom: -103px;
    left: -230px;
}

.main .img4 {
    left: -230px;
    bottom: -103px;
}

.fcontainer {
   align-self: center;
    overflow: hidden;
    width: 402px;
    height: 100%;
    left: 536px;
    top: 267px;
    border-radius: 6px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    display: block;
    padding: 25px;

}

.form-container{
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
}

.form-control:focus {
    background: transparent!important;
}

input:-webkit-autofill {
    background-color: transparent!important;
}

.login_title{
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
}
.login_subtitle {
    font-family: Montserrat;
    color: rgba(80, 81, 97, 1);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.social-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px; 
}

.google-login-button-container {
    display: inline-block;
}

.google-login-button {
    background:#DE5246;
    border-color: #DE5246;
    width: 350px;
    display: block;
    padding: 13px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: center;
    margin-top: 24px;
}


.facebook, .kep-login-facebook.metro{
    width: 250px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: #3B5998;
    box-shadow: 0px 5px 25px 5px rgba(34, 35, 47, 0.04);
    border-radius: 4px!important;
    color: #FFFFFF;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    padding: 13px;

}


.facebook:hover , .kep-login-facebook.metro{
    color: white;
}

.fcontainer .or {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.fcontainer .item {
    margin-right: 0;
}


.fcontainer .item.incorect {
    border: 1px solid #D32E26;
    box-sizing: border-box;
    border-radius: 4px;
}

.fcontainer .inputs .invalid {
    color: rgba(211, 46, 38, 1);
    text-align: end;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}

.fcontainer .inputs {
    position: relative;
    top: 24px;
}

.fcontainer .rememberMe {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: black;
}
.fcontainer .forgotPassword {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(65, 21, 224, 1);
    padding: 0;
    text-align: end;
}

.fcontainer .rememberMe input {
    margin-right: 10px;
    position: relative;
    top: 2px
}

.fcontainer .form-footer {
    margin-top: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}
.dashboard .item {
    background: #FFFFFF;
    box-shadow: 0px 5px 11px rgba(51, 58, 58, 0.13);
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    padding: 20px;
    height: calc(100% - 28px);
    margin: 0 0 28px 0;
}

.dashboard .item h2 {
    margin-bottom: 32px;
}

.dashboard .item a {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(34, 35, 47, 1);
    cursor: pointer;
}

.dashboard .item.congrat h2 {
    margin-bottom: 6px;
}

.dashboard .item.congrat .desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    margin-bottom: 8px;
}
.dashboard .item.congrat .views {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: rgba(65, 21, 224, 1);
    margin-bottom: 8px;
    margin-top: 48px;
}
.dashboard .item.congrat button {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    background: rgba(65, 21, 224, 0.8);
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.12);
    border-radius: 6px;
    height: 32px;
    padding: 8px 15px;
}

.dashboard .item.congrat .congratImage img{
    float: right;
}

.dashboard .item .statisticIdem {
    margin: auto;
}

.dashboard .item .statisticIdem .statisticImage { 
    width: 48px;
    height: 48px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.dashboard .item .statisticIdem .statisticImage.green {
    background: rgba(5, 179, 127, 0.2);
}
.dashboard .item .statisticIdem .statisticImage.yellow {
    background: rgba(255, 177, 10, 0.3);
}
.dashboard .item .statisticIdem .statisticImage.blue {
    background: rgba(0, 207, 232, 0.3);
}

.dashboard .item .statisticIdem .statisticInfo img {
    width: 20px;
    margin-left: 5px;
    margin-top: -4px;
}

.dashboard .item .statisticIdem .statisticInfo h3{

    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 1);
}

.dashboard .item.chart {
    display: flex;
    height: 295px;
}

.dashboard .item .chart1 {
    padding: 54px 0 0 0 ;
    width: calc(84% - 20px);
    display: flex;
}

.dashboard .item .chart2 {
    padding: 54px 0 0 0 ;
    width: 70%;
    display: flex;
}

.dashboard .item .chart2 .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(94, 88, 115, 1);
    position: absolute;
    top: 20px;
    left: 20px;
}

.dashboard .item .chart1Statistic {
    width: 16%;
    display: inline-flex;
    align-items: center;
    align-self: center;
    flex-direction: column;
    margin-left: 20px;
}

.dashboard .item .chart1Statistic .crtItem {
    border: 5px solid rgba(5, 179, 127, 0.12);
    box-sizing: border-box;
    width: 100%;
    aspect-ratio : 1;
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: 16px 25px;
}

.dashboard .item .chart1Statistic .crtItem:first-child {
    margin-bottom: 20px;
}

.dashboard .item .chart1Statistic .title {
    align-self: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: rgba(5, 179, 127, 1);
    margin-bottom: 3px;
}

.dashboard .item .chart1Statistic .crtItem.yellow{
    border: 5px solid rgba(255, 177, 10, 0.12);
}

.dashboard .item .chart1Statistic .crtItem.yellow .title {
    color: rgba(255, 177, 10, 1);
}

.dashboard .item .chart1Statistic .description {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
}
.dashboard .item .chart1Statistic img {
    width: 12px;
    margin-top: -4px;
}


.dashboard .item .chart2Statistic {
    width: 225px;
    margin-left: 16px;
    position: relative;
}

.dashboard .item .chart2Statistic .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(34, 35, 47, 1);
    margin-bottom: 12px;
    margin-top: 54px;
}

.dashboard .item .chart2Statistic .itemCS {
    margin-bottom: 12px;
}

.dashboard .item .chart2Statistic .itemCS .title {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: rgba(80, 81, 97, 1);
    margin: 0;
}

.dashboard .item .chart2Statistic .itemCS .desc{
    font-weight: 400;
    font-size: 8px;
    line-height: 18px;
    color: rgba(154, 159, 167, 1);
    display: inline-flex;
   
    
}

.dashboard .item .chart2Statistic .itemCS .value {
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    color: rgba(34, 35, 47, 1);
    float: right;
    display: inline-flex;
    margin-top: 5px;
}

.dashboard .item .chart2Statistic .separator {
    background: #E5E5F0;
    border-radius: 0px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1px;
    height: 217px;
    position: absolute;
    left: 100px;
    bottom: -24px;
}


.dashboard .item .chart2Statistic .objective .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(34, 35, 47, 1);
    margin: 28px 0 10px 0;
}

.dashboard .item .chart2Statistic .objective .value {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(255, 177, 10, 1);
    margin-bottom: 6px;
}

.dashboard .item .chart2Statistic .objective .progress{
    height: 5px;
    background-color: rgba(243, 243, 243, 0.6);
    border-radius: 10px;

}

.dashboard .item .chart2Statistic .objective .progress .progress-bar{
    background-color: #FFB10A!important;
    border-radius: 10px;

}


.dashboard .item .itemActivity {
    margin-bottom: 26px;
}

.dashboard .item .itemActivity .itemImg {
    border-radius: 26px;
    height: 48px;
    width: 48px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.dashboard .item .itemActivity .itemImg img {
    width: 18px;
}

.dashboard .item .itemActivity.purple .itemImg {
    background: rgba(65, 21, 224, 0.12);
}
.dashboard .item .itemActivity.green .itemImg {
    background: rgba(5, 179, 127, 0.12);;
}
.dashboard .item .itemActivity.yellow .itemImg {
    background: rgba(255, 177, 10, 0.12);;
}
.dashboard .item .itemActivity.blue .itemImg {
    background: rgba(0, 207, 232, 0.12);
}

.dashboard .item .itemActivity .title{
    display: inline-flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    margin-bottom: 12px;
}
.dashboard .item .itemActivity .activityProgress{
    display: inline-flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    float: right;
}

.dashboard .item .itemActivity .progress{
    height: 5px;
    background-color: rgba(243, 243, 243, 0.6);
    border-radius: 10px;

}

.dashboard .item .itemActivity.purple .progress .progress-bar{
    background-color: #4115E0!important;
    border-radius: 10px;

}
.dashboard .item .itemActivity.green .progress .progress-bar{
    background-color: #05B37F!important;
    border-radius: 10px;

}
.dashboard .item .itemActivity.yellow .progress .progress-bar{
    background-color: #FFB10A!important;
    border-radius: 10px;

}
.dashboard .item .itemActivity.blue .progress .progress-bar{
    background-color: #00CFE8!important;
    border-radius: 10px;

}

.itemOpportunity {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #F8F8F8;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}

.itemOpportunity .logoCont{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
}

.itemOpportunity.purple .logoCont{
    background: #4115E0;
}
.itemOpportunity.yellow .logoCont{
    background: #FFB10A;
}
.itemOpportunity.green .logoCont{
    background: #05B37F;
}
.itemOpportunity.blue .logoCont{
    background: #00CFE8;
}


.itemOpportunity .title{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(80, 81, 97, 1);
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

.itemOpportunity .dateStart{
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: rgba(155, 155, 155, 1);
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    float: left;
}
.itemOpportunity .dateEnd{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: rgba(155, 155, 155, 1);
    text-align: center;
    background: rgba(222, 2, 10, 0.2);
    border-radius: 2px;
    padding: 2px 4px;
}


.itemOpportunity .separator {
    background: linear-gradient(0deg, rgba(228, 232, 238, 0) 0%, #E4E8EE 52.08%, rgba(228, 232, 238, 0) 100%);
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    width: 1px;
}

.itemOpportunity .detailsOp {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.itemOpportunity .value {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: rgba(65, 21, 224, 1);
    margin-bottom: 12px;
}

.itemOpportunity .details {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
}

.itemOpportunity .details img{
    width: 12px;
    margin-left: 5px;
}

.tableALO .tab {
    background: #F3F3F3;
    border-radius: 4px 4px 0px 0px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: black;
    padding: 10px 18px;
}

.tableALO .tabsALO {
    height: calc(100% - 48px);
}

.tableALO .col.active .tab, .tableALO .col-md-4.active .tab{
    background: white;
}

.tableALO  a{
    text-decoration: none;
}

.tableALO .item table {
    width: 100%;
}

.tableALO .item .table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

.tableALO .item table thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 8px;
    text-align: center;
    height: 40px;
}


.tableALO .item table tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 8px;
    text-align: center;
    height: 40px;
}

.tableALO .item table tbody td img{
    margin-right: 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.tableALO .item table td.name,.tableALO .item table th.name {
    text-align: left;
    width: 20%;
}

.tableALO .item table .startDate {
    display: inline;
    margin-right: 10px;
}
.tableALO .item table .endDate {
    display: inline;
    background: #FFE0E0;
    border-radius: 10px;
    padding: 3px 9px;
}

.tableALO .item table .total {
    font-size: 12px;
    line-height: 14px;
    color: black;
    width: 70px;
}

.tableALO .item table .obiectiv {
    font-size: 12px;
    line-height: 14px;
    color: rgba(65, 21, 224, 1);
    width: 70px;
    
}

.tableALO .item table .etape,.report .etape {
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 177, 10, 1)!important;
    position: relative;
    
}
.tableALO .item table .etape div.value {
    position: absolute;
    right: 0;
    top: 3px;
}
.report .etape div.value {
    position: absolute;
    left: 17px;
    top: 12px;
    color: #008053;
}

.tableALO .item table .etape .progress,.report .etape .progress{
    height: 5px;
    background-color: rgba(243, 243, 243, 0.6);
    border-radius: 10px;
    justify-content: left;
    margin-left: 55px;
}

.tableALO .item table .etape .progress .progress-bar, .report .etape .progress .progress-bar {
    background-color: #05B37F!important;
    border-radius: 10px;
    height: 5px;
}

.tableALO .item table .comision {
    font-size: 12px;
    line-height: 14px;
    color: black;
    width: 70px;
    
}

.tableALO .item table .leadValue {
    color: rgba(65, 21, 224, 1)!important;
}

.tableALO .item table thead th.empty{
    border: 0;
    background: #FFFFFF;
}
.tableALO .item table thead th.green{
    height: 49px;
    padding: 12px 16px;
    text-align: center;
    border: 1px solid #E4E8EE;
    background: rgba(5, 179, 127, 0.1);
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}

.tableALO .item table thead th.green.title{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.tableALO .item .imgTable {
    width: 48px;
    height: 48px;
    background-color: rgba(0, 207, 232, 1);
    border-radius: 50%;
    align-content: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.tableALO .item .imgTable img {
    width: 25px;
}

.tableALO .item .tabLeads .itemTop {
    padding: 16px 0 16px 20px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
}

.tableALO .item .tabLeads .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    position: relative;
    height: 56px;
}

.tableALO .item .tabLeads .separator {
    background: #E5E5F0;
    border-radius: 0px;
    width: 1px;
    height: 52px;
    position: absolute;
    right: 0;
    bottom: 0;
}


.tableALO .item .tabLeads .startDate {
    display: inline;
    margin-right: 10px;
}

.tableALO .item .tabLeads .endDate {
    display: inline;
    background: #FFE0E0;
    border-radius: 10px;
    padding: 3px 9px;
}

.tableALO .item .tabLeads .budget {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 12px;
}

.tableALO .item .tabLeads td {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: black;
    padding: 8px;
}

.tableALO .item .tabLeads td img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 16px;
}

.tableALO .item .tabLeads td  .view {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: inline;
}
.tableALO .item .tabLeads td  .view img {
    width: 12px;
    margin-left: 5px;
}


.viewOps .maintitle{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.viewOps .maintitle div {
    display: inline-block;
}

.viewOps .maintitle a img{
    margin-right: 10px;
}

.viewOps .maintitle div:last-child {
    float: right;
}

.viewOps .filters{
    margin-bottom: 8px;
    display: inline-flex;
}

.viewOps .filters .item {
    margin-right: unset;
    width: 196px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
            user-select: none;
}

.viewOps .filters a{
    text-decoration: none;
    color: rgba(80, 81, 97, 1);
}

.viewOps .filters .item img {
    width: 12px;
    margin-right: 12px;
}

.viewOps .filters .item.checkbox{
    display: inline-flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.viewOps .filters .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.viewOps .item.select {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.viewOps .item.select div {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: unset;
    margin-top: 0;
}
.viewOpCard{
    position: relative;
    margin: 0 0px;
}

.viewOpCard {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 2px 5px 11px rgba(31, 72, 72, 0.13);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.viewOpCard .info{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.viewOpCard .info .logoCont {
    width: 144px;
    height: 144px;
    margin-bottom: 14px;
    border-radius: 50%;
}

.viewOpCard .info .username{
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 10px;
}

.viewOpCard .info a{
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-decoration: none;
    color: rgba(34, 35, 47, 1);
}

.viewOpCard .info a img{
    width: 12px;
    margin-left: 8px;
}

.viewOpCard .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: black;
    margin-bottom: 12px;

}

.viewOpCard .date {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-content: center;
    align-items: center;
    color: rgba(80, 81, 97, 1);
    margin-bottom: 8px;
}

.viewOpCard .date img{
    width: 15px;
    margin-right: 10px;
}

.viewOpCard .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #505161;
    margin-bottom: 20px;
    margin-right: 48px;

}


.viewOpCard .separator {
    background: linear-gradient(0deg, rgba(228, 232, 238, 0) 0%, #E4E8EE 52.08%, rgba(228, 232, 238, 0) 100%);
    position: absolute;
    right: 0;
    top: 0;
    height: 216px;
    width: 1px;
}

.viewOpCard .opButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-end;

}

.viewOpCard .opButtons .btn {
    align-self: flex-end;
    margin-bottom: 16px;
}

.viewOpCard button{
    width: 128px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    align-self: center;
    justify-self: flex-end;

}

.viewOpCard .btn-primary a{
    text-decoration: none;
    color: white;
}

.viewOpCard .btn-secondary a{
    text-decoration: none;
    color: rgba(65, 21, 224, 1);
}

.viewOpCard button img {
    margin-right: 10px;
}

.viewOpCard .collaboration {
    width: 200px;
    margin-right: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 11px 0;
    box-shadow: 0px 4px 4px  rgba(115, 103, 240, 0.1);
    color: rgba(0, 0, 0, 1);
    text-align: center;
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
}

.viewOpCard .manager {
    width: 400px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 1);
    padding: 11px 0;
    background: #f1effd;
    box-shadow: 0px 4px 4px rgba(115, 103, 240, 0.1);
    border-radius: 4px;
    text-align: center;
}
.viewOpContent {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin: 0;
    padding: 20px;
}

.viewOpContent .viewOpCard {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
    padding: 0;
}
.viewOp .mainTitle{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.viewOp .filters{
    margin-bottom: 8px;
    align-items: center;
    display: inline-flex;
}

.viewOp .filters .item {
    width: 196px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
            user-select: none;
}

.viewOp .filters a{
    text-decoration: none;
    color: rgba(80, 81, 97, 1);
}

.viewOp .filters .item img {
    width: 12px;
    margin-right: 12px;
}

.viewOp .filters .item.checkbox{
    display: inline-flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.viewOp .filters .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.viewOp .description h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: black;
    margin-bottom: 20px;
}

.viewOpContent .description {
    margin-top: 34px;
}
.viewOp .description div {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: rgba(80, 81, 97, 1);
    margin-right: 200px;
}

.viewOp .files {
    padding: 20px;
}

.viewOp .files .mainTitle {
    margin-top: 48px;
    margin-bottom: 38px;
}

.viewOp .files .addFile {
    display: inline-block;
    position: absolute;
    right: 48px;
}


.viewOp .files .access {
    display: inline-block;
    align-content: center;
    right: 32px;
    background-color: #05b37f;
    border-color: #05b37f;
    box-shadow: 0px 2px 5px rgba(5, 179, 127, 0.25);
    border-radius: 4px;;
    width: 300px;
    margin-top: 20px;
}



.viewOp .files .addFile img{
    margin-right: 10px;
}

.viewOp .files .saveLead ,.viewOp .infoTask .createTask {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    border-top: 1px solid #E4E8EE;
    padding-top: 20px;
    padding-left: 20px;
}
.viewOp .files .saveLead .btn,.viewOp .infoTask .createTask  .btn{
    width: 230px;
    height: 40px;
}

.viewOp .opButtons .back a {
    color: rgba(5, 179, 127, 1)!important;
}
.companyHeader {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
}

.companyHeader .logoCont{
    width: 132px;
    height: 132px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(115, 103, 240, 0.12);
    align-items: center;
    justify-content: center;
    display: flex;
    color: #7367F0;
    font-size: 24px;
    font-weight: 700;
    margin: auto;
}
.companyHeader .logoCont img {
    width: 132px;
    height: 132px;
}

.companyHeader .companyTitle{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: black;
    display: flex;
    justify-content: center;
    margin: 16px 0 8px 0;
}

.companyHeader .companyDomain{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #505161;
    text-align: center;
}

.companyHeader .companyDesc{
    margin: 12px 40px 0 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}
.companyHeader button{
    margin-top: 26px;
}
.companyHeader .col-md-3{
    margin-top: 26px;
}

.companyHeader .separator {
    background: linear-gradient(0deg, rgba(228, 232, 238, 0) 0%, #E4E8EE 52.08%, rgba(228, 232, 238, 0) 100%);
    position: absolute;
    right: 0;
    top: 0;
    height: 216px;
    width: 1px;
}

.contactInfo {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 0 0 40px;
}

.contactInfo .element{
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.contactInfo .element .icon{
    width: 15px;
    margin-right: 18px;
    
}
.puExperience .activityDom{
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
}

.puExperience .activityDom .activityDomItem{
    border-top: 1px solid #E4E8EE;
    margin-right: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #22232F;
    height: 44px;
    align-items: center;
    display: flex;
}

.puExperience .activityDom .activityDomItem img {
    margin-right: 12px;
}


.tabContent .activity {
    margin-top: 20px;
}



.tabContent .activity .activityDom .itemActivity {
    margin-bottom: 26px;
}

.tabContent .activity .activityDom .itemActivity .itemImg {
    border-radius: 26px;
    height: 48px;
    width: 48px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.tabContent .activity .activityDom .itemActivity .itemImg img {
    width: 18px;
}

.tabContent .activity .activityDom .itemActivity.purple .itemImg {
    background: rgba(65, 21, 224, 0.12);
}
.tabContent .activity .activityDom .itemActivity.green .itemImg {
    background: rgba(5, 179, 127, 0.12);;
}
.tabContent .activity .activityDom .itemActivity.yellow .itemImg {
    background: rgba(255, 177, 10, 0.12);;
}
.tabContent .activity .activityDom .itemActivity.blue .itemImg {
    background: rgba(0, 207, 232, 0.12);
}

.tabContent .activity .activityDom .itemActivity .title{
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    margin-bottom: 12px;
}
.tabContent .activity .activityDom .itemActivity .activityProgress{
    display: inline-flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    float: right;
}

.tabContent .activity .activityDom .itemActivity .progress{
    height: 5px;
    background-color: rgba(243, 243, 243, 0.6);
    border-radius: 10px;

}

.tabContent .activity .activityDom .itemActivity.purple .progress .progress-bar{
    background-color: #4115E0!important;
    border-radius: 10px;

}
.tabContent .activity .activityDom .itemActivity.green .progress .progress-bar{
    background-color: #05B37F!important;
    border-radius: 10px;

}
.tabContent .activity .activityDom .itemActivity.yellow .progress .progress-bar{
    background-color: #FFB10A!important;
    border-radius: 10px;

}
.tabContent .activity .activityDom .itemActivity.blue .progress .progress-bar{
    background-color: #00CFE8!important;
    border-radius: 10px;

}


.profileUserMenu {
    margin-top: 32px;
}

.profileUserMenu .tab {
    background: #F3F3F3;
    border-radius: 4px 4px 0px 0px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: black;
    padding: 10px 18px;
}
.profileUserMenu a.active .tab{
    background: white;
}

.profileUserMenu  a{
    text-decoration: none;
}

.puExperience .leadsContainer{
    margin-right: 26px;
    margin-top: 20px;
}


name.puJobHistory .description {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: rgba(34, 35, 47, 1);
    margin-bottom: 32px;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

table.companies thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 12px 16px;
    width: 16%;
    text-align: left;
}


table.companies tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 12px 16px;
    text-align: center;
    width: 16%;
    height: 40px;
}

table.companies tbody td div{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

table.companies tbody td .units{
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

table.companies .name {
    width: 35%;
    text-align: left;
}
table.companies .name img {
    margin-right: 10px;
}

table.companies .date{
    font-size: 10px;
    line-height: 14px;
}

table.companies .budget{
    color: rgba(0, 0, 0, 1);
}

table.companies .commission{
    color: rgba(65, 21, 224, 1);
}

table.companies .date .dateEnd {
    background: #FFE0E0;
    border-radius: 10px;
    width :70px;
    margin-left: 8px;
    color: black;
    height: 14px;
    text-align: center;
    justify-content: center;
    padding: 2px;
}


.companySettings p{
    margin-bottom: 24px;
}

.companySettings .item {
    margin-right: 0;
}

.companySettings h3{
    margin-top: 8px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: black;
}

.companySettings .subtitle{
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: black;
}

.companySettings .companyDescription{
    background: #FFFFFF;
    border: 0.5px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(185, 185, 195, 0.2);
    border-radius: 4px;
    height: 148px;
    display: block;
    width: 100%;
    margin-bottom: 24px;
}

.companySettings .aboutCollab{
    display: inline-block;
    margin-bottom: 24px;
}

.companySettings .aboutCollab div{
    display: inline-block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}




.leads .mainTitle {
    padding: 0;
    margin: 0;
}

.leads .mainTitle div{
    margin-bottom: 32px;
    display: inline-flex;
}

.leads .mainTitle  div:last-child{
    float: unset;
}

.leads .mainTitle a img{
    margin-right: 10px;
}

.leads .list {
    padding: 0;
    margin: 0;
}

.leads .filters{
    margin-bottom: 8px;
    align-items: center;
    display: inline-flex;
    float: unset;
}

.leads .filters .item {
    width: 196px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
            user-select: none;
}

.leads .filters a{
    text-decoration: none;
    color: rgba(80, 81, 97, 1);
}

.leads .filters .item img {
    width: 12px;
    margin-right: 12px;
}


.viewLeadContent {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin: 0;
    padding: 20px;
}


.viewLeadContent .viewOpCard {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
}
.viewLead .mainTitle{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.viewLead .filters{
    margin-bottom: 8px;
    align-items: center;
    display: inline-flex;
}

.viewLead .filters .item {
    width: 196px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
            user-select: none;
}

.viewLead .filters a{
    text-decoration: none;
    color: rgba(80, 81, 97, 1);
}

.viewLead .filters .item img {
    width: 12px;
    margin-right: 12px;
}

.viewLead .filters .item.checkbox{
    display: inline-flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.viewLead .filters .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.viewLead .description h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: black;
    margin-bottom: 20px;
}

.viewLeadContent .description {
    margin-top: 34px;
}

.viewLead .description div {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: rgba(80, 81, 97, 1);
    margin-right: 200px;
}

.viewLead .files {
    padding: 20px;
}

.viewLead .files .mainTitle {
    margin-top: 48px;
    margin-bottom: 38px;
}

.viewLead .files .addFile {
    display: inline-block;
    position: absolute;
    right: 48px;
}


.viewLead .files .access {
    display: inline-block;
    align-content: center;
    right: 32px;
    background-color: #05b37f;
    border-color: #05b37f;
    box-shadow: 0px 2px 5px rgba(5, 179, 127, 0.25);
    border-radius: 4px;;
    width: 300px;
    margin-top: 20px;
}



.viewLead .files .addFile img{
    margin-right: 10px;
}

.viewLead .files .saveLead ,.viewLead .infoTask .createTask {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    border-top: 1px solid #E4E8EE;
    padding-top: 20px;
    padding-left: 20px;
}
.viewLead .files .saveLead .btn,.viewLead .infoTask .createTask  .btn{
    width: 230px;
    height: 40px;
}

.viewLead .opButtons .back a {
    color: rgba(5, 179, 127, 1)!important;
}
.collabCard {
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    flex-direction: column;
    margin-right: 28px;
    margin-bottom: 20px;
}

a {
    text-decoration: none;
}
.collabCard .imgLeft{
    position: absolute;
    left: 0;
    top: 0;
    
}

.collabCard .imgRight{
    position: absolute;
    right: 0;
    top: 42px;
    
}

.collabCard .profileImage {
    width: 150px;
    height: 150px;
    margin-top: 32px;
    margin-bottom: 16px;
}

.collabCard .profileImage img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
.collabCard .name{
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 10px;
}

.collabCard .domain {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
}
.collabCard .viewProfile {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    position: relative;
    color: black;
}

.collabCard .viewProfile img {
    position: absolute;
    top: -1px;
    height: 18px;
}
.collabCard .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    width: 205px;
    margin-bottom: 16px;
    height: 32px;
    color: rgba(80, 81, 97, 1);
}

.collabCard .message {
    margin-bottom: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.collabCard .message div{
    display: inline-block;
}

.collabCard .btn {
    margin-right: 16px;
    width: 150px;
}
.collabCard .btn.request {
    margin-right: 10px;
    width: 88px;
}

.collabCard .btn.request:first-child {
    margin-left: 16px;
}

.collabCard .btn img{
    margin-right: 10px;
}

.collabCard .message .action {
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.05);
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.collabCard .message .action.heart {
    margin-right: 8px;
}
.collabCard .message .action.heart.active {
    background-color: rgba(65, 21, 224, 0.1);
}
.collaboration .title{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.collaboration .filters{
    margin-bottom: 8px;
    align-items: center;
    display: inline-flex;
}

.collaboration .filters .item {
    width: 196px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
            user-select: none;
}


.collaboration .filters .item img {
    width: 12px;
    margin-right: 12px;
}

.collaboration .filters .item.checkbox{
    display: inline-flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.collaboration .filters .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.collaboration .filters .favourite {
    width: 32px;
    height: 32px;
    margin-bottom: 24px;
    background: #FFFFFF;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.05);
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
}
.collaboration .filters .favourite.checked {
    border: 0.5px solid #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15);
}

.collaboration .title{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.collaboration .filters{
    margin-bottom: 8px;
    align-items: center;
    display: inline-flex;
}

.collaboration .filters .item {
    width: 196px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
            user-select: none;
}


.collaboration .filters .item img {
    width: 12px;
    margin-right: 12px;
}

.collaboration .filters .item.checkbox{
    display: inline-flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.collaboration .filters .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.collaboration .filters .favourite {
    width: 32px;
    height: 32px;
    margin-bottom: 24px;
    background: #FFFFFF;
    border: 0.5px solid rgba(65, 21, 224, 0.25);
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.05);
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
}
.collaboration .filters .favourite.checked {
    border: 0.5px solid #4115E0;
    box-shadow: 0px 2px 5px rgba(65, 21, 224, 0.15);
}

.inbox {
    height: 600px;
}


.inbox .rowMessage {
    width: 100%;
    height: 100%;
    display: inline-flex;
}
@media (max-width: 1045px) {
    .inbox .rowMessage {
        display: block;
    }
    .inbox .rowMessage .colMessage{
        margin-top: 20px;
    }
    .inbox .rowMessage .colMessagesList{
        margin-right: 0!important;
    }
    .inbox .rowMessage .colMessagesList .messagesList{
        width: 100%;
    }
}
@media (max-width: 576px) {
    .inbox .messageWindow .topBar .search input{
        display: none;
    }
}

.inbox .rowMessage .colMessagesList {
    height: 100%;
    margin-right: 28px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}

.inbox .rowMessage .colMessage {
   width: 100%;
    height: 100%;

}

.inbox .colMessagesList .messagesList {

    padding: 0 20px 20px 20px;
    height: 100%;
    overflow-y: scroll;
    width: 260px;
}

.inbox .messagesList .headerMessages {
    margin-bottom: 16px;
}

.inbox .messagesList .title {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    display: inline-flex;
    height: 36px;
    align-items: center;
}
.inbox .messagesList .newMessage {
    color: white;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    display: inline-flex;
    background-color: #4115E0;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    float: right;
}


.inbox .messageWindow {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    height: 100%;
    width: 100%;
}

.inbox .messageWindow .topBar {
    padding: 20px;
    display: inline-flex;
    height: 72px;
    width: 100%;
    border-bottom: 1px solid #E4E8EE;
    position: relative;
}

.inbox .messageWindow .topBar .image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 32px;
}

.inbox .messageWindow .topBar .image img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.inbox .messageWindow .topBar .name {
    color: rgba(34, 35, 47, 1);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 4px;
}
.inbox .messageWindow .topBar .lastSeen {
    color: rgba(154, 159, 167, 1);
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    width: 200px;
}

.inbox .messageWindow .topBar .search input {
    width: 200px;
    padding: 6px;
}
.inbox .messageWindow .topBar .search .usersList {
    margin-top: 10px;
    width: 200px;
}

.active .bubble {
    background-color: rgba(65, 21, 224, 0.2);
}

.inbox .messageWindow .topBar .itemHeader {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline;
}

.inbox .messageWindow .topBar input {
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    width: 320px;
}
.inbox .messageWindow .topBar .usersList{
    width: 320px;
    position: absolute;
    top: 51px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #E4E8EE;
}

.inbox .messageWindow .messages {
    height: calc(100% - 72px);
    padding: 20px;
}

.inbox .messageWindow .messages .messagesList{
    height: calc(100% - 64px);
    overflow-y: scroll;
    
}

.inbox .messageWindow .messages .messagesList::-webkit-scrollbar, .inbox .colMessagesList .messagesList::-webkit-scrollbar {
    width: 3px;
}
.inbox .messageWindow .messages .messagesList::-webkit-scrollbar-thumb,.inbox .colMessagesList .messagesList::-webkit-scrollbar-thumb {
    background: #c8cacd;
    border-radius: 4px;
    float: bottom;
    position: fixed;
    bottom: 0;
}
.inbox .messageWindow .messages .messagesList::-webkit-scrollbar-track, .inbox .colMessagesList .messagesList::-webkit-scrollbar-track{
    background: #F3F3F3;
    border-radius: 4px;
}

.inbox .colMessage .messagesList .message-list-container {
    height: 100%;
    padding-right: 20px;
}


.inbox .messageWindow .messages .messagesList img {
    width: 48px;
    height: 48px;
}

.inbox .messageWindow .messages .addMessage {
    padding: 12px 20px;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}
.inbox .messageWindow .messages .addMessage input {
    background: #FFFFFF;
    border: 0.5px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 16px;
    width: calc(100% - 113px);
    outline: none;
}

.inbox .messageWindow .messages .addMessage img {
    margin-right: 10px;
}


.inbox .dropdown {
    width: 100%;
    position: relative;
    left: 0;
}

.inbox .dropdown .btn-info {
    background-color: transparent;
    border: 0!important;
    box-shadow: none!important;
    float: right;
}

.inbox .dropdown-toggle::after{
    display: none;
    border: 0;
}

.inbox .dropdown-menu {
    padding: 12px 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 180px;
}
.discussionItem {
    display: inline-flex;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
}

.discussionItem.active {
    background-color: rgba(65, 21, 224, 0.1);
}

.discussionItem.unread {
    background-color: rgba(65, 21, 224, 0.2);
}

.discussionItem .image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 10px;
}

.discussionItem .image img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.discussionItem .name {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
}

.discussionItem .lastMessage {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
}
.messageItem {
  display: flex;
  flex-direction: column;
}

.messageItem .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.messageItem .bubble-container {
  font-size: 14px;
  display: flex;
}

.messageItem.mine .bubble-container {
  justify-content: flex-end;
}

.messageItem.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.messageItem.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}
div.active .messageItem.end .bubble-container .bubble {
  background-color: rgba(65, 21, 224, 0.3);
}

.messageItem.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.messageItem.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.messageItem .bubble-container .bubble {
  margin: 1px 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: rgba(65, 21, 224, 0.1);
  padding: 12px 16px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.messageItem.mine .bubble-container .bubble {
  background: rgba(65, 21, 224, 0.1);
  
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.image_message, .image_message img{
  width: 48px;
  border-radius: 50%;
}
.financeInvoices .title {
    color: black;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
}

.financeInvoices .invoicesList{
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 20px;
}
.financeInvoices .table-container{
    overflow-x: auto;
}

.financeInvoices table.invoices img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
}

.addPayment input {
    outline: none;
    margin: 16px;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    border-radius: 4px;
}

.addPayment button {
    margin-right: 16px;
}
.financeInvoice .header {
    background: #FFFFFF;
    border: 1px solid #D0DBEA;
    box-sizing: border-box;
    border-radius: 6px;
    height: 102px;
    padding: 0;
    margin: 20px 0;
}

.financeInvoice .header .title {
    background: linear-gradient(180deg, #4115E0 25.01%, rgba(87, 60, 184, 0.6) 100%);
    border-radius: 6px 0px 0px 6px;
    color: white;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.financeInvoice .invoiceTo .title {
    color: black;
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.financeInvoice .header .info, .financeInvoice .invoiceTo .info {
    color: rgba(34, 35, 47, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.financeInvoice .header .info div, .financeInvoice .invoiceTo .info div{
    display: inline-flex;
    text-align: left;
    align-items: flex-start;
    margin: 20px 20px 0 0 ;
}


.financeInvoice .header .info div img, .financeInvoice .invoiceTo .info div img {
    width: 15px;
    height: 100%;
    margin-right: 18px;
}

.financeInvoice .invoiceTo {
    margin-bottom: 44px;
}

.financeInvoice .invoiceTo .info div .invoice, .financeInvoice .invoiceTo .info div .date {
    margin: 0 24px 0 0 ; 
    color: rgba(65, 21, 224, 1);
}

.financeInvoice .tasks {
    width: 100%;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color:#E4E8EE;
}

table.tasks thead th{
    border: 1px solid #E4E8EE;
    background: #F8F8F8;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 35, 47, 1);
    padding: 12px 16px;
    width: 16%;
    text-align: center;
}


table.tasks tbody td {
    border: 1px solid #E4E8EE;
    background: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(80, 81, 97, 1);
    padding: 12px 16px;
    text-align: center;
    width: 16%;
}

table.tasks tbody td img{
    margin-right: 4px;
}

table.tasks td.dat {
    color: rgba(65, 21, 224, 1);
}
table.tasks td.obi {
    color: rgba(255, 177, 10, 1);
}


.financeInvoice .printButton{
    float: right;
}

.financeInvoice .printButton img{
    margin-right: 10px;
}
.packages {
    text-align: center;
    justify-content: center;
}

.packages .mainTitle {
    margin-top: 57px;
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 20px;
}

.packages .firstContainer {
    background: #FFFFFF;
    box-shadow: 0px 5px 12px rgba(183, 183, 183, 0.25);
    border-radius: 6px;
    padding: 26px;
    margin-top: 60px;
}

.packages .firstContainer .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 16px;
}

.packages .firstContainer .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgba(20, 20, 20, 1);
}

.packages .mainDesc {
    width: 60%;
    margin: auto;
}

.packages .secondContainer {
    background: #FFFFFF;
    box-shadow: 0px 5px 12px rgba(183, 183, 183, 0.25);
    border-radius: 6px;
    margin-top: 60px;
    padding: 24px;
    height: calc(100% - 60px);
}

.packages .secondContainer .title {
    padding: 26px;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}

.packages .secondContainer .packageList {
    text-align: left;
}
.packages .secondContainer .packageList div {
    margin-bottom: 16px;
}
.packages {
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;
}

.packages .mainTitle {
    margin-top: 57px;
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 20px;
}

.packages .firstContainer {
    background: #FFFFFF;
    box-shadow: 0px 5px 12px rgba(183, 183, 183, 0.25);
    border-radius: 6px;
    padding: 26px;
    margin-top: 60px;
}

.packages .firstContainer .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 16px;
}

.packages .firstContainer .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgba(20, 20, 20, 1);
}

.packages .mainDesc {
    width: 60%;
    margin: auto;
}

.packages .secondContainer {
    background: #FFFFFF;
    box-shadow: 0px 5px 12px rgba(183, 183, 183, 0.25);
    border-radius: 6px;
    margin-top: 60px;
    padding: 24px;
    height: calc(100% - 20px);
    position: relative;
}

.packages .secondContainer.active {

    box-shadow: 0px 5px 12px rgba(65, 21, 224, 0.6);
}

.packages .secondContainer .title {
    padding: 26px;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}

.packages .secondContainer .packageList {
    text-align: left;
}
.packages .secondContainer .packageList div {
    margin-bottom: 16px;
}

.packages .secondContainer button {
    position: absolute;
    bottom: 24px;
    left: 24px;
    width: calc(100% - 48px);
}
.report .item {
    width: 120px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
            user-select: none;
    padding: 16px;
}

.report .item img{
    width: 12px;
    margin-right: 12px;
}

.report .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}
.report .item {
    width: 140px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
            user-select: none;
    padding: 16px;
}

.report .item img{
    width: 12px;
    margin-right: 12px;
}

.report .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.report .dropdown {
    display: inline-flex;
}

.report .dropdown-toggle {
    padding: 0;
}

.report table.tasks tbody td.actions{
    align-items: start;
}

.report table.tasks tbody td.actions img.mess{
    margin-bottom: 8px;
    float: start;
    align-self: start;
}

.report .dropdown-menu {
    width: 150px;
    -webkit-transform: translate(-112px, 24px);
            transform: translate(-112px, 24px);
    cursor: pointer;
}
.leads .mainTitle {
    padding: 0;
    margin: 0;
}

.leads .mainTitle div{
    margin-bottom: 32px;
    display: inline-flex;
}

.leads .mainTitle  div:last-child{
    float: right!important;
}

.leads .mainTitle a img{
    margin-right: 10px;
}

.leads .list {
    padding: 0;
    margin: 0;
}


.leads .mainTitle {
    padding: 0;
    margin: 0;
}

.leads .mainTitle div{
    margin-bottom: 32px;
    display: inline-flex;
}

.leads .mainTitle  div:last-child{
    float: unset;
}

.leads .mainTitle a img{
    margin-right: 10px;
}

.leads .list {
    padding: 0;
    margin: 0;
}

.leads .filters{
    margin-bottom: 8px;
    align-items: center;
    display: inline-flex;
    float: unset;
}

.leads .filters .item {
    width: 196px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
            user-select: none;
}

.leads .filters a{
    text-decoration: none;
    color: rgba(80, 81, 97, 1);
}

.leads .filters .item img {
    width: 12px;
    margin-right: 12px;
}


.viewLeadContent {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin: 0;
    padding: 20px;
}


.viewLeadContent .viewOpCard {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
}
.viewLead .mainTitle{
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
}

.viewLead .filters{
    margin-bottom: 8px;
    align-items: center;
    display: inline-flex;
}

.viewLead .filters .item {
    width: 196px;
    margin-right: 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    -webkit-user-select: none;
            user-select: none;
}

.viewLead .filters a{
    text-decoration: none;
    color: rgba(80, 81, 97, 1);
}

.viewLead .filters .item img {
    width: 12px;
    margin-right: 12px;
}

.viewLead .filters .item.checkbox{
    display: inline-flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.viewLead .filters .item.checked {
    border: 1px solid #4115E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
    border-radius: 4px;
    color: #4115E0;
}

.viewLead .description h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: black;
    margin-bottom: 20px;
}

.viewLeadContent .description {
    margin-top: 34px;
}

.viewLead .description div {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: rgba(80, 81, 97, 1);
    margin-right: 200px;
}

.viewLead .files {
    padding: 20px;
}

.viewLead .files .mainTitle {
    margin-top: 48px;
    margin-bottom: 38px;
}

.viewLead .files .addFile {
    display: inline-block;
    position: absolute;
    right: 48px;
}


.viewLead .files .access {
    display: inline-block;
    align-content: center;
    right: 32px;
    background-color: #05b37f;
    border-color: #05b37f;
    box-shadow: 0px 2px 5px rgba(5, 179, 127, 0.25);
    border-radius: 4px;;
    width: 300px;
    margin-top: 20px;
}



.viewLead .files .addFile img{
    margin-right: 10px;
}

.viewLead .files .saveLead ,.viewLead .infoTask .createTask {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    border-top: 1px solid #E4E8EE;
    padding-top: 20px;
    padding-left: 20px;
}
.viewLead .files .saveLead .btn,.viewLead .infoTask .createTask  .btn{
    width: 230px;
    height: 40px;
}

.viewLead .opButtons .back a {
    color: rgba(5, 179, 127, 1)!important;
}
.terms {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.terms .background {
    width: 100%;
    height: 70%;
    display: flex;
    position: absolute;
    top: 0;
    justify-content: center;
}

.terms .img1,.terms .img2,.terms .img3,.terms .img4 {
    display: flex;
    position: absolute;
}

.terms .img1 {
    width: 721px;
    display: flex;
    align-self: center;
}

.terms .img2 {
    width: 135px;
    bottom: 40px;
    right: 0px;
}

.terms .img3 {
    width: 819px;
    bottom: 40px;
    left: calc(50% - 410px);
}

.terms .img4 {
    left: 0;
    bottom: 40px;
}

.terms .login {
    z-index: 10000;

}

.terms .fcontainer {
   align-self: center;
    overflow: hidden;
    width: 60vw;
    height: 100%;
    z-index: 100;
    left: 536px;
    top: 267px;
    border-radius: 6px;
    background: #FFFFFF;
    border: 1px solid #E4E8EE;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    display: block;
    padding: 25px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

.terms .fcontainer::-webkit-scrollbar {
    display: none;
}


.terms .form-container{
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
}

.terms .form-control:focus {
    background: transparent!important;
}

.terms input:-webkit-autofill {
    background-color: transparent!important;
}

.terms .login_title{
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
}
.terms .login_subtitle {
    font-family: Montserrat;
    color: rgba(80, 81, 97, 1);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}


